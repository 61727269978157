<template>
  <div>
    <div class="box">
      <div class="main">
        <div class="operation">
          <el-button @click="add" type="primary">+ 新建</el-button>
        </div>
        <!-- 表格内容 -->
        <div class="primary">
          <el-table height="600px" ref="multipleTable" :data="PurchaseList" tooltip-effect="dark"
                    style="width: 100%;margin-bottom: 10px;" :header-cell-style="{
              color: '#333333',
              backgroundColor: 'rgb(240, 247, 253)',
            }" border>
            <el-table-column type="selection" width="40" align="center">
            </el-table-column>
            <el-table-column type="index" label="序号" width="100px" align="center"></el-table-column>
            <el-table-column prop="Name" label="组织名称" align="left"></el-table-column>
            <el-table-column label="地址URL" align="left">
              <template v-slot="{ row }">
                <a :href="row.LoginPageUrl" target="_blank">{{ row.LoginPageUrl }}</a>
              </template>
            </el-table-column>
            <el-table-column label="配置" width="180" align="left">
              <template slot-scope="scope">
                <el-button type="text" style="margin-right: 10px;font-size: 16px; color: #3978E7;font-weight: 400;"
                           @click="EditOrganization(scope.row)">编辑
                </el-button>
                <el-button style="font-size: 16px;color: #3978E7;font-weight: 400;" type="text"
                           @click="DeleteOrganization(scope.row)">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <Pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.rows"
                        @pagination="OrganizationGetPageList"></Pagination>
          </div>
        </div>
      </div>
      <!-- 弹出框 -->
      <div class="add-procure">
        <el-dialog :visible.sync="addProcureDialog" width="60vw" top="10vh" v-dialogDrag @close="handleClose"
                   @open="handleOpen">
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">{{ flag === 0 ? '新增组织' : '编辑组织' }}</span>
          </div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="登录页设计" name="first">
              <div class="addOrganizationContent">
                <el-form label-width="auto" :model="PurchaseFrom" style="flex: 1;">
                  <el-form-item label="组织名称">
                    <!-- <el-input v-model="PurchaseFrom.Name"></el-input> -->
                    <el-select v-model="PurchaseFrom.Name" filterable remote :remote-method="remoteMethod"
                               v-el-select-loadmore="loadmore" @change="change" placeholder="请选择"
                               style="width: 100%;">
                      <el-option v-for="(item, index) in hospital" :key="index" :label="item.Name" :value="item.Name">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="网站LOGO">
                    <el-image
                      v-if="imageUrl.logo" :src="imageUrl.logo" style="width: 100px; height: 100px"
                      :preview-src-list="[imageUrl.logo]"/>
                    <el-upload action="#" :auto-upload="false" :show-file-list="false"
                               :on-change="handleLogo">
                      <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="标题图片">
                    <el-image
                      v-if="imageUrl.header" :src="imageUrl.header" style="width: 100px; height: 100px"
                      :preview-src-list="[imageUrl.header]"/>
                    <el-upload action="#" :auto-upload="false" :show-file-list="false"
                               :on-change="handleHeader">
                      <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="背景图片">
                    <el-image
                      v-if="imageUrl.body" :src="imageUrl.body" style="width: 100px; height: 100px"
                      :preview-src-list="[imageUrl.body]"/>
                    <el-upload action="#" :auto-upload="false" :show-file-list="false"
                               :on-change="handleBody">
                      <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="底部文字">
                    <el-input placeholder="请输入底部文字" v-model="PurchaseFrom.RootText"></el-input>
                  </el-form-item>
                  <el-form-item label="语音服务地址">
                    <el-input placeholder="请输入语音服务地址" v-model="PurchaseFrom.ServiceConfig"></el-input>
                  </el-form-item>
                  <el-form-item label="系统名称">
                    <el-input placeholder="请输入系统名称" v-model="PurchaseFrom.HeaderText"></el-input>
                  </el-form-item>
                  <el-form-item label="短信签名">
                    <el-input placeholder="请输入短信签名" v-model="PurchaseFrom.SmsSignText"></el-input>
                  </el-form-item>
                  <el-form-item label="布局方式">
                    <el-radio-group v-model="PurchaseFrom.layout">
                      <el-radio label="TTOB">上下布局</el-radio>
                      <el-radio label="LTOR">左右布局</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="展示栏文本1">
                    <el-input placeholder="请输入展示栏文本1" v-model="PurchaseFrom.CenterText1"></el-input>
                  </el-form-item>
                  <el-form-item label="展示栏文本2">
                    <el-input placeholder="请输入展示栏文本2" v-model="PurchaseFrom.CenterText2"></el-input>
                  </el-form-item>
                </el-form>
                <div>
                  <img src="../../assets/image/login_show.png" alt="">
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="患者信息设置" name="second" v-if="flag != 0">
              <el-table :data="patientField" style="width: 100%" border>
                <el-table-column prop="FieldName" label="字段名称">
                  <template #default="{ row }">
                    <span>{{ bindEnum[row.FieldName] }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="IsEnable" label="是否显示" width="90">
                  <template #default="{ row }">
                    <el-switch v-model="row.IsEnable" active-color="#13ce66" inactive-color="#ff4949"
                               @change="updateOrder(row)"></el-switch>
                  </template>
                </el-table-column>
                <el-table-column prop="IsRequired" label="是否必填" width="90">
                  <template #default="{ row }">
                    <el-switch v-model="row.IsRequired" active-color="#13ce66" inactive-color="#ff4949"
                               @change="updateOrder(row)"></el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="顺序" width="90">
                  <template #default="{ row }">
                    <el-input v-model="row.Index" @change="updateOrder(row)"></el-input>
                    <!-- <el-switch v-model="row.IsRequired" active-color="#13ce66" inactive-color="#ff4949"></el-switch> -->
                  </template>
                </el-table-column>
              </el-table>
              <Pagination :total="pageTotal" :page.sync="pageParams.page" :limit.sync="pageParams.rows"
                          @pagination="handleOpen"></Pagination>
            </el-tab-pane>
            <el-tab-pane label="项目信息设置" name="fourth" v-if="flag != 0">
              <el-table :data="XMFieldsList" style="width: 100%" border>
                <el-table-column prop="LabelName" label="字段名称"></el-table-column>
                <el-table-column prop="IsEnable" label="是否显示" width="90">
                  <template #default="{ row }">
                    <el-switch v-model="row.IsEnable" active-color="#13ce66" inactive-color="#ff4949"
                               @change="updateOrder(row)"></el-switch>
                  </template>
                </el-table-column>
                <el-table-column prop="IsRequired" label="是否必填" width="90">
                  <template #default="{ row }">
                    <el-switch v-model="row.IsRequired" active-color="#13ce66" inactive-color="#ff4949"
                               @change="updateOrder(row)"></el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="顺序" width="90">
                  <template #default="{ row }">
                    <el-input v-model="row.Index" @change="updateOrder(row)"></el-input>
                    <!-- <el-switch v-model="row.IsRequired" active-color="#13ce66" inactive-color="#ff4949"></el-switch> -->
                  </template>
                </el-table-column>
              </el-table>
              <Pagination :total="XMTotal" :page.sync="XMpageParams.page" :limit.sync="XMpageParams.rows"
                          @pagination="XMFieldsConfig"></Pagination>
            </el-tab-pane>
            <el-tab-pane label="系统文本设置" name="thirdly" v-if="flag != 0">
              <el-form>
                <el-form-item label="新增问卷文本" label-width="120px">
                  <el-input v-model="textSet[0].LabelName" @change="updateText(textSet[0])"></el-input>
                </el-form-item>
                <el-form-item label="随访记录文本" label-width="120px">
                  <el-input v-model="textSet[1].LabelName" @change="updateText(textSet[1])"></el-input>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="流程设计" name="five" v-if="flag != 0">
              <el-form label-width="auto" :model="flowForm" :inline="true">
                <el-form-item label="名称">
                  <el-input placeholder="请输入名称" v-model="flowForm.Name"></el-input>
                </el-form-item>
                <el-form-item label="类型">
                  <el-select v-model="flowForm.Type" placeholder="请选择" style="width: 150px;" @change="flowForm.Url = ''">
                    <el-option v-for="(item, index) in [{name: '外部', val: '0'}, {name: '内部', val: '1'}]" :key="index" :label="item.name" :value="item.val">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="地址URL">
                  <template v-if="flowForm.Type == 0">
                      <el-input type="url" placeholder="请输入地址" v-model="flowForm.Url"></el-input>
                  </template>
                  <template v-if="flowForm.Type == 1">
                    <el-select v-model="flowForm.Url" placeholder="请选择">
                      <el-option v-for="(item, index) in navList" :key="index" :label="item.name" :value="item.path">
                      </el-option>
                    </el-select>
                  </template>
                </el-form-item>
                <el-button @click="addFlow" type="primary">+ 新增</el-button>
              </el-form>
              <el-table :data="flowList" style="width: 100%" border>
                <el-table-column prop="Name" label="名称"></el-table-column>
                <el-table-column prop="Url" label="地址URL">
                  <template v-slot="{row}">
                    <span v-if="row.Type == 1">{{ row.Url }}</span>
                    <a v-else :href="row.Url" target="_blank">{{ row.Url }}</a>
                  </template>
                </el-table-column>
                <el-table-column prop="Type" label="类型" width="90">
                  <template v-slot="{row}">
                    <span>{{row.Type == 0 ? '外部' : '内部'}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="150">
                  <template v-slot="{row}">
                    <el-button type="text" @click="editFlow(row)">编辑</el-button>
                    <el-button type="text" @click="delFlow(row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>

          <div class="btn" style="margin-top: 20px;">
            <button @click="Cancel">取消</button>
            <button @click="AddOrganizationData">保存</button>
          </div>
        </el-dialog>
      </div>
      <el-dialog :visible.sync="editFlowDialog" width="60vw" top="10vh" v-dialogDrag @close="resetFlowForm">
        <div slot="title" class="header-title" style="color: #fff;">编辑地址</div>
        <el-form label-width="auto" :model="editFlowForm" :inline="true" style="margin-top: 20px;">
          <el-form-item label="名称">
            <el-input placeholder="请输入名称" v-model="editFlowForm.Name"></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="editFlowForm.Type" placeholder="请选择" style="width: 150px;" @change="editFlowForm.Url = ''">
              <el-option v-for="(item, index) in [{name: '外部', val: '0'}, {name: '内部', val: '1'}]" :key="index" :label="item.name" :value="item.val">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地址URL">
            <template v-if="editFlowForm.Type == 0">
                <el-input type="url" placeholder="请输入地址" v-model="editFlowForm.Url"></el-input>
            </template>
            <template v-if="editFlowForm.Type == 1">
              <el-select v-model="editFlowForm.Url" placeholder="请选择">
                <el-option v-for="(item, index) in navList" :key="index" :label="item.name" :value="item.path">
                </el-option>
              </el-select>
            </template>
          </el-form-item>
        </el-form>
        <div class="btn">
          <button @click="editFlowDialog = false">取消</button>
          <button @click="editConfirm">保存</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Pagination from "../../components/Pagination/index.vue"
import administrators from "../../api/administrators"
import ProjectManagement from "../../api/ProjectManagement"
import PatientCenter from "../../api/PatientCenter"
import apiLogin from "@/api/login";

export default {
  directives: {
    'el-select-loadmore': {
      bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector(
          '.el-select-dropdown .el-select-dropdown__wrap'
        )
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            binding.value()
          }
        })
      }
    }
  },
  data() {
    return {
      listType: "",
      addProcureDialog: false,
      PurchaseList: [],
      PurchaseFrom: {
        Name: '',
        BindId: '',
        RootText: '',
        LogoImage: "",
        HeaderImage: "",
        BodyImage: "",
        ServiceConfig: "",
        CenterText1: "",
        CenterText2: "",
        layout: ""
      },
      imageUrl: {
        logo: '',
        header: '',
        body: ''
      }, //回显图片
      type: 1,
      total: 0,
      listQuery: {
        page: 1,
        rows: 20,
      },
      hospital: [],
      listQueryAdd: {
        Key: '',
        page: 1,
        rows: 20
      },
      FD: null,
      flag: 0,
      activeName: 'first',
      bindEnum: {
        MiCardNo: '医保卡号',
        No: '患者编号',
        VisitDate: '就诊日期',
        Name: '患者姓名',
        OperationDate: '手术日期',
        NameAbbr: '姓名缩写',
        RiskRating: '风险等级',
        Tel: '患者电话',
        Ward: '住院病区',
        WX: '微信',
        Day: '住院天数',
        CertType: '证件类型',
        DischargeDate: '出院日期',
        CertNo: '证件号码',
        DischargeDiagnosis: '出院诊断',
        Birthday: '出生日期',
        SecondaryDiagnosis: '次要诊断',
        Age: '年龄(岁)',
        DischargeMode: '出院方式',
        Sex: '性别',
        AttendingDoctor: '主治医生',
        Height: '身高(m/米)',
        FollowUpDoctor: '随访医生',
        Weight: '体重(kg/千克)',
        Edu: '教育程度',
        QQ: '患者QQ',
        RandomNumber: '随机号',
        EcName: '紧急联系人姓名',
        GroupNumber: '组别号',
        EcTel: '紧急联系人电话',
        Source: '患者来源',
        Address: '患者地址',
        AdmissionDiagnosis: '入院诊断',
        AdmissionDate: '入院时间',
        GroupType: '组别',
      },
      patientField: [],
      pageParams: {
        Type: "P",
        OrganizationId: "",
        page: 1,
        rows: 10
      },
      pageParams2: {
        Type: "T",
        OrganizationId: "",
        page: 1,
        rows: 10
      },
      pageTotal: 0,
      textSet: [{LabelName: ''}, {LabelName: ''}],
      XMpageParams: {
        Type: "J",
        OrganizationId: "",
        page: 1,
        rows: 10
      },
      XMFieldsList: [],
      XMTotal: 0,
      flowParams: {
        OrganizationId: "",
      },
      flowList: [],
      flowForm: {
        Name: "",
        Type: "",
        Url: "",
        OrganizationId: "",
      },
      editFlowForm: {
        Name: "",
        Type: "",
        Url: "",
        OrganizationId: "",
      },
      editFlowDialog: false,
    }
  },
  computed: {
    navList() {
      let arr = []
      this.$store.state.navList.forEach(item => {
        arr.push({
          name: item.navName,
          path: item.path,
        })
        if (item.tab&&item.path!='/HomePage') {
          item.tab.forEach(i => {
            arr.push({
              name: i.label,
              path: `${item.path}?name=${i.name}`
            })
          })
        }
      })
      return arr
    },
  },
  components: {
    Pagination
  },
  created() {
    this.OrganizationGetPageList()
  },
  methods: {
    handleLogo(file) { //处理LOGO图片
      const isJPG = file.raw.type === 'image/jpeg'
      const isPNG = file.raw.type === 'image/png'
      // const isLt2M = file.raw.size / 1024 / 1024 < 0.5  //限制上传文件的大小
      if (!isPNG && !isJPG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!')
        return false
      } else {
        this.imageUrl.logo = URL.createObjectURL(file.raw);//赋值图片的url，用于图片回显功能
        this.PurchaseFrom.LogoImage = file.raw
      }
    },
    handleHeader(file) { //处理Header图片
      const isJPG = file.raw.type === 'image/jpeg'
      const isPNG = file.raw.type === 'image/png'
      // const isLt2M = file.raw.size / 1024 / 1024 < 0.5  //限制上传文件的大小
      if (!isPNG && !isJPG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!')
        return false
      } else {
        this.imageUrl.header = URL.createObjectURL(file.raw);//赋值图片的url，用于图片回显功能
        this.PurchaseFrom.HeaderImage = file.raw
      }
    },
    handleBody(file) { //处理Body图片
      const isJPG = file.raw.type === 'image/jpeg'
      const isPNG = file.raw.type === 'image/png'
      // const isLt2M = file.raw.size / 1024 / 1024 < 0.5  //限制上传文件的大小
      if (!isPNG && !isJPG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!')
        return false
      } else {
        this.imageUrl.body = URL.createObjectURL(file.raw);//赋值图片的url，用于图片回显功能
        this.PurchaseFrom.BodyImage = file.raw
      }
    },

    handleClose() {
      Object.keys(this.PurchaseFrom).forEach(i => {
        this.PurchaseFrom[i] = ""
      })
      Object.keys(this.imageUrl).forEach(i => {
        this.imageUrl[i] = ""
      })
      this.addProcureDialog = false;
    },
    //取消
    Cancel() {
      this.PurchaseFrom.Name = '';
      this.PurchaseFrom.BindId = '';
      this.addProcureDialog = false;
    },
    remoteMethod(name) {
      this.hospital = [];
      this.listQueryAdd.page = 1;
      this.Company(name);
    },
    //单位列表接口
    Company(name) {
      const parameter = {
        Key: name,
        page: this.listQueryAdd.page,
        rows: this.listQueryAdd.rows
      };
      ProjectManagement.GetPageListCompanyData(parameter).then(res => {
        if (res.data.Status == 1) {
          // this.hospital=res.data.Data
          res.data.Data.rows.forEach((item) => {
            this.hospital.push(item)
          })
        } else {
          alert(res.data.Message)
        }
      })
    },
    loadmore() {
      this.listQueryAdd.page += 1;
      this.Company();
    },
    add() {
      this.flag = 0
      this.FD = new FormData()
      this.Company('')
      this.addProcureDialog = true
      this.type = 1
    },
    OrganizationGetPageList() {//列表
      const parameter = {
        Key: '',
        page: this.listQuery.page,
        rows: this.listQuery.rows
      }
      administrators.OrganizationGetPageListData(parameter).then(res => {
        if (res.data.Status == 1) {
          // console.log(res, "====");
          this.total = res.data.Data.total
          this.PurchaseList = res.data.Data.rows
        } else {
          alert(res.data.Message)
        }
      })
    },
    change(e) {
      this.hospital.forEach((item, index) => {
        if (item.Name == e) {
          this.PurchaseFrom.BindId = item.Id;
          this.PurchaseFrom.Name = e;
        }
      });
    },
    //添加组织和编辑
    AddOrganizationData(row) {
      if (this.PurchaseFrom.Name != "") {
        if (this.type == 1) { //新增
          this.FD.append("Name", this.PurchaseFrom.Name)
          this.FD.append("BindId", this.PurchaseFrom.BindId)
          this.FD.append("RootText", this.PurchaseFrom.RootText)
          this.FD.append("LogoImage", this.PurchaseFrom.LogoImage)
          this.FD.append("HeaderImage", this.PurchaseFrom.HeaderImage)
          this.FD.append("BodyImage", this.PurchaseFrom.BodyImage)
          this.FD.append("ServiceConfig", this.PurchaseFrom.ServiceConfig)
          this.FD.append("HeaderText", this.PurchaseFrom.HeaderText)
          this.FD.append("SmsSignText", this.PurchaseFrom.SmsSignText)
          this.FD.append("layout", this.PurchaseFrom.layout)
          this.FD.append("CenterText1", this.PurchaseFrom.CenterText1)
          this.FD.append("CenterText2", this.PurchaseFrom.CenterText2)

          administrators.AddOrganizationData(this.FD).then(res => {
            if (res.data.Status == 1) {
              this.OrganizationGetPageList()
              this.PurchaseList.push(res.data.Data)
              this.$message({
                message: '保存成功',
                type: 'success'
              });
              this.addProcureDialog = false
            } else {
              alert(res.data.Message)
            }
          })
        } else { //编辑
          this.FD.append("Name", this.PurchaseFrom.Name)
          this.FD.append("BindId", this.PurchaseFrom.BindId)
          this.PurchaseFrom.RootText && this.FD.append("RootText", this.PurchaseFrom.RootText)
          this.FD.append("LogoImage", this.PurchaseFrom.LogoImage)
          this.FD.append("HeaderImage", this.PurchaseFrom.HeaderImage)
          this.FD.append("BodyImage", this.PurchaseFrom.BodyImage)
          this.FD.append("ServiceConfig", this.PurchaseFrom.ServiceConfig)
          this.FD.append("Id", this.PurchaseFrom.Id)
          this.FD.append("HeaderText", this.PurchaseFrom.HeaderText)
          this.FD.append("SmsSignText", this.PurchaseFrom.SmsSignText)
          this.FD.append("layout", this.PurchaseFrom.layout)
          this.FD.append("CenterText1", this.PurchaseFrom.CenterText1)
          this.FD.append("CenterText2", this.PurchaseFrom.CenterText2)

          administrators.EditOrganizationData(this.FD).then(res => {
            if (res.data.Status == 1) {
              this.OrganizationGetPageList()
              this.PurchaseList = this.PurchaseList.map(item => {
                if (item.Id == this.PurchaseFrom.Id) {
                  item = this.PurchaseFrom
                }
                return item
              })
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.handleOpen()
              this.addProcureDialog = false
            } else {
              alert(res.data.Message)
            }
          })
        }
      }
    },
    //编辑
    EditOrganization(row) {
      console.log("row", row);
      this.pageParams.OrganizationId = row.Id
      this.pageParams2.OrganizationId = row.Id
      this.XMpageParams.OrganizationId = row.Id
      this.flowParams.OrganizationId = row.Id
      this.flowForm.OrganizationId = row.Id
      this.flag = 1
      this.FD = new FormData()
      this.type = 2
      // const regex = /[?&]id=([^&#]*)/;
      // const match = regex.exec(row.LoginPageUrl);
      // console.log(match);
      // const id = match && decodeURIComponent(match[1]);
      this.getDiyLogin(row.Id).then(r => {
        console.log("r -->", r)
        this.imageUrl.logo = r?.logoImageUrl
        this.imageUrl.header = r?.headerImageUrl
        this.imageUrl.body = r?.bodyImageUrl
        this.PurchaseFrom.RootText = r?.rootText
        this.PurchaseFrom.HeaderText = r?.headerText
        this.PurchaseFrom.SmsSignText = r?.SmsSignText
        this.PurchaseFrom.CenterText1 = r?.centerText1
        this.PurchaseFrom.CenterText2 = r?.centerText2
        this.$set(this.PurchaseFrom, 'layout', r?.layout)

      })
      this.PurchaseFrom = JSON.parse(JSON.stringify(row));
      console.log(this.PurchaseFrom, "++++");
      this.addProcureDialog = true
    },
    async getDiyLogin(id) {
      let res = await apiLogin.diyLogin(id)
      return res.data.Data.config.pageConfig
    },
    // 删除
    DeleteOrganization(row) {
      const parameter = []
      parameter.push(row.Id)
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        administrators.DeleteOrganizationData(parameter).then(res => {
          if (res.data.Status == 1) {
            this.PurchaseList = this.PurchaseList.filter(item => {
              return item.Id != row.Id
            })
            this.$message({
              message: '删除成功',
              type: 'success'
            });
          } else {
            alert(res.data.Message)
          }
        })
      })
    },
    async handleOpen() {
      await this.HZFieldsConfig()
      await this.XMFieldsConfig()
      await this.flowConfig()
    },
    // 患者信息字段设置
    async HZFieldsConfig() {
      let res = await PatientCenter.GetOrderPage(this.pageParams)
      this.pageTotal = res.data.Data.total
      res.data.Data.rows.forEach((i, idx) => {
        i.Index = idx + 1 + (this.pageParams.page - 1) * this.pageParams.rows
      })
      this.patientField = res.data.Data.rows
      let res2 = await PatientCenter.GetOrderPage(this.pageParams2)
      if (res2.data.Data.rows.length) this.textSet = res2.data.Data.rows
    },
    // 项目信息字段设置
    async XMFieldsConfig() {
      let res = await PatientCenter.GetOrderPage(this.XMpageParams)
      this.XMTotal = res.data.Data.total
      res.data.Data.rows.forEach((i, idx) => {
        i.Index = idx + 1 + (this.XMpageParams.page - 1) * this.XMpageParams.rows
      })
      this.XMFieldsList = res.data.Data.rows
      
      console.log(res.data.Data)
    },
    // 流程设计设置
    async flowConfig() {
      let res = await PatientCenter.GetExtendList(this.flowParams)
      this.flowList = res.data.Data
    },
    addFlow(){
      PatientCenter.AddExtend(this.flowForm).then(res => {
        if(res.data.Status == 0){
          this.$message.error(res.data.Message);
        }
        this.flowConfig()
        this.flowForm = {
          Name: "",
          Type: "",
          Url: "",
        }
      })
    },
    editFlow(row){
      this.editFlowDialog = true
      this.editFlowForm = {...row}
    },
    resetFlowForm(){
      this.editFlowForm = {
        Name: "",
        Type: "",
        Url: "",
      }
    },
    editConfirm(){
      PatientCenter.editExtend(this.editFlowForm).then(res => {
        if(res.data.Status == 0){
          this.$message.error(res.data.Message);
        }
        this.editFlowDialog = false
        this.flowConfig()
      })
    },
    delFlow(row){
      PatientCenter.deleteExtend([row.Id]).then(res => {
        this.$message.success('删除成功');
        this.flowConfig()
      })
    },
    async updateOrder(row) {
      console.log("row -->", row)
      let params = {
        Id: row.Id,
        ParameterName: row.ParameterName,
        IsEnable: row.IsEnable,
        IsRequired: row.IsRequired,
        Index: row.Index - 1,
        OrganizationId: row.OrganizationId
      }
      let res = await PatientCenter.updateOrder(params)
    },
    async updateText(item) {
      let params = {
        Id: item.Id,
        OrganizationId: this.pageParams2.OrganizationId,
        LabelName: item.LabelName
      }
      let res = await PatientCenter.updateOrder(params)
    }
  },
}
</script>
<style lang='scss' scoped>
:deep(.el-dialog .el-dialog__header) {
  margin-bottom: 0;
}

.addOrganizationContent {
  display: flex;
  align-items: center;
  gap: 20px;

  & > div {
    img {
      width: 300px;
    }
  }

  & > form {
    & .el-form-item {
      position: relative;
    }

    & .el-form-item:nth-of-type(2) {
      &::after {
        content: "建议上传尺寸100*100px";
        position: absolute;
        top: 30%;
        left: 50%;
        color: #999999;
      }
    }

    & .el-form-item:nth-of-type(3) {
      &::after {
        content: "建议上传尺寸900*90px";
        position: absolute;
        top: 30%;
        left: 50%;
        color: #999999;
      }
    }

    & .el-form-item:nth-of-type(4) {
      &::after {
        content: "建议上传尺寸1920*540px";
        position: absolute;
        top: 30%;
        left: 50%;
        color: #999999;
      }
    }
  }

}

:deep(.el-dialog) {
  margin-top: 10vh !important;
}

.box {
  width: 100%;
  padding: 20px 40px 40px 40px;
  box-sizing: border-box;
  height: 745px;

  .operation {
    margin-bottom: 20px;
  }

  // ::v-deep .el-input__inner{
  //   width: 380px;
  // }
}

:deep(.avatar-uploader .el-upload) {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

:deep(.avatar-uploader .el-upload:hover) {
  border-color: #409EFF;
}

:deep(.avatar-uploader-icon) {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

:deep(.avatar) {
  width: 100px;
  height: 100px;
  display: block;
}

:deep(.el-dialog__body) {
  padding-top: 0px !important;
}
</style>
