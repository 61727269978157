import Vue from "vue";
import VueRouter from "vue-router";
import {session} from "@/utils/storage";
import {local} from "@/utils/storage";

Vue.use(VueRouter);

const routes = [
    {
        path: "/che",//测试
        name: "che",
        component: () => import('../views/che.vue'),
    },
    {
        path: "/",//登录页
        name: "login",
        component: () => import('../views/login.vue'),
    },
    {
        path: "/EmptyPage",//空白页跳转
        name: "EmptyPage",
        component: () => import('../views/EmptyPage.vue'),
    },
    {
        path: "/VerificationCode",//验证码
        name: "VerificationCode",
        component: () => import('../views/VerificationCode.vue'),
    },
    {//机器人详情
        path: '/RobotCenterDetails',
        name: "RobotCenterDetails",
        component: () => import('../views/navPage/RobotCenterDetails.vue')
    },
    {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
    },
    {
        path: "/MenuCommon",//菜单公用页
        name: "MenuCommon",
        component: () => import('../views/MenuCommon.vue'),
        children: [
            // 项目管理首页
            {
                path: '/HomePage',
                name: "HomePage",
                component: () => import('../views/navPage/HomePage.vue'),
                meta: {
                    menuindex: 1
                }
            },
            // 机器人中心
            {
                path: '/RobotCenter',
                name: "RobotCenter",
                component: () => import('../views/navPage/RobotCenter.vue'),
            },
            //问卷中心
            {
                path: '/questionnaire',
                name: 'questionnaire',
                component: () => import('../views/navPage/questionnaire.vue'),
                meta: {
                    menuindex: 2
                }
            },
            // 患者列表
            {
                path: '/patentList',
                name: 'patentList',
                component: () => import('../views/navPage/patentList.vue'),
                meta: {
                    menuindex: 3
                }

            },
            // //患者画像
            // {
            //   path: '/portrayal',
            //   name: 'portrayal',
            //   component: ()=> import('../views/navPage/portrayal.vue')
            // },
            // 二级菜单患者画像
            {
                path: '/PatientPortrait',
                name: 'PatientPortrait',
                component: () => import('../views/otherPage/PatientPortrait.vue')
            },
            {
                path: '/detail',
                name: 'Detail',
                component: () => import('../views/otherPage/detail.vue')
            },
            {
                path: '/projectDetail',
                name: 'projectDetail',
                component: () => import('../views/otherPage/projectDetail.vue')
            },
            // {
            //   path: '/participants',
            //   name: 'participants',
            //   component: () => import('../views/otherPage/participants.vue')
            // },
            {
                path: '/collDetail',
                name: 'collDetail',
                component: () => import('../views/otherPage/collDetail.vue')
            },
            //数据统计
            {
                path: '/statistics',
                name: 'statistics',
                component: () => import('../views/navPage/statistics.vue'),
                meta: {
                    menuindex: 6
                }
            },
            //查询导出
            {
                path: '/derive',
                name: 'derive',
                component: () => import('../views/navPage/derive.vue'),
                meta: {
                    menuindex: 5
                }
            },
            //随访任务
            {
                path: '/task',
                name: 'task',
                component: () => import('../views/navPage/task.vue'),
                meta: {
                    menuindex: 7
                }
            },
            //工作汇报
            {
                path: '/report',
                name: 'report',
                component: () => import('../views/navPage/report.vue'),
                meta: {
                    menuindex: 8
                }
            },
            { // 查看项目信息
                path: "/seeProject",
                name: "seeProject",
                component: () => import('../views/otherPage/seeProject.vue')
            },
            { // 数据中心
                path: "/DataCenter",
                name: "DataCenter",
                component: () => import('../views/navPage/DataCenter.vue'),
                meta: {
                    menuindex: 4
                }
            },
            { //数据分析
                path: "/DataAnalysis",
                name: "DataAnalysis",
                component: () => import('../views/navPage/DataAnalysis.vue')
            },
            // 管理员维护
            { // 导航页
                path: "/navber",
                name: "navber",
                redirect: '/member',
                component: () => import('../views/adminMaintain/navbar.vue'),
                children: [
                    //成员维护
                    {
                        path: "/member",
                        name: "member",
                        component: () => import('../views/adminMaintain/member.vue')
                    },
                    {
                        path: "/Participants",
                        name: "Participants",
                        component: () => import('@/views/adminMaintain/Participants.vue')
                    },
                    //权限设置
                    {
                        path: "/jurisdiction",
                        name: "jurisdiction",
                        component: () => import('../views/adminMaintain/jurisdiction.vue')
                    },
                    //采购组织维护
                    {
                        path: "/procure",
                        name: "procure",
                        component: () => import('../views/adminMaintain/procure.vue')
                    },
                    //审核
                    {
                        path: "/toexamine",
                        name: "toexamine",
                        component: () => import('../views/adminMaintain/toexamine.vue')
                    },
                    //医院维护
                    // {
                    //     path: "/hosptail",
                    //     name: "hosptail",
                    //     component: ()=> import('../views/adminMaintain/addHosptail.vue')
                    //   },
                    //随访设置
                    // {
                    //   path: "/followUp",
                    //   name: "followUp",
                    //   component: ()=> import('../views/adminMaintain/followUp.vue')
                    // },
                ]
            },
        ]
    },
    {
        path: '/iframe',
        name: "iframe",
        component: () => import('../views/iframe.vue')
    },
    {
        path: '/transfer',
        component: () => import('../views/transfer/index.vue')
    },
    {
        path: '/authSetting',
        component: () => import('../views/auth/setting.vue')
    }
];

const router = new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes,
});

// router.beforeEach((to, from, next) => {
//   if(to.path == '/'){
//     local.clear();
//     session.clear();
//   };
//   console.log(to,from)
//   if (session.get('Token')) { // 如果已经登录的话
//     next();
//   } else {
//     if (to.path == '/'||to.path == '/VerificationCode') { // 如果是登录页面的话，直接next()
//       next();
//     } else { // 否则 跳转到登录页面
//       next({
//         path: '/'
//       });
//     }
//   }
// });

router.beforeEach((to, from, next) => {
    if (window.parent !== window) sendUrlToParent(to)
    let token = local.get("Token") || ""; //在本地存储中获取token;
    if (token) {
        next();
    } else {
        if (to.path == "/" || to.path == '/VerificationCode') {
            local.clear();
            session.clear();
            next();
        }
            // else if (to.path == '/HomePage' && from.path == '/') {
            //   next();
        // }
        else {
            if (to.name === 'iframe' || to.path === '/transfer' || to.path === '/authSetting') {
                next()
            } else {
                next({path: "/"}); //跳转页面到login页
            }
        }
    }
    next();
});

// 路由切换时向父页面发送路由地址
function sendUrlToParent(target) {
    // 
    window.parent.postMessage({
        type: "ROUTE",
        path: target.fullPath
    }, "*");
}

export default router;
