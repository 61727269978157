<template>
  <div class="tolead" :style="{height: $store.state.menuFlag?'100%':'calc(100% - 70px)'}">
    <div class="title">
      <div class="left">
        <div style="margin-right: 10px;display:flex;align-items: center;" class="project">
          <span style="width:80px">项目:</span>
          <!--          <el-select v-model="value" value-key="Id" placeholder="请选择" @change="ProjectChange"-->
          <!--                     :class="{ classborder: border == 1 }">-->
          <!--            <el-option v-for="(item, index) in Project" :key="index" :label="item.Name" :value="item"></el-option>-->
          <!--          </el-select>-->
          <!--          <el-cascader-->
          <!--            v-model="value" @change="ProjectChange"-->
          <!--            :options="Project" :class="{ classborder: border == 1 }"-->
          <!--            :props="{ multiple: true, checkStrictly: true, value: 'Id', label: 'Name'}"-->
          <!--            clearable></el-cascader>-->
          <Cascader :value.sync="value" :options="Project" @MyChange="ProjectChange" :multiple="true"
                    :class="{ classborder: border == 1 }">>
          </Cascader>
        </div>
        <div style="width:270px;;display:flex;align-items: center;" class="KeyWord">
          <span style="width:80px">关键字</span>
          <el-input type="text" v-model="keyword" style="width: 200px;"/>
        </div>
        <div style="display:flex;align-items: center;" class="timer">
          <span style="width:80px">开始时间:</span>
          <el-date-picker v-model="StartTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
                          size="small"></el-date-picker>
          <span style="width:80px">结束时间:</span>
          <el-date-picker v-model="EndTime" type="date" size="large" value-format="yyyy-MM-dd"
                          placeholder="选择日期"></el-date-picker>
        </div>
        <div style="margin-right: 10px;display:flex;align-items: center;">
          <el-button @click="query" icon="el-icon-search" type="primary">查询</el-button>
          <el-button @click="Reset" class="czhi">
            <img src="../../assets/image/icon/czhi.png" alt style="width: 14px;height: 14px"/>重置
          </el-button>
        </div>
        <div>
          <span class="yuan" style="width: 500px;">
            <!-- <span style="width:20px;height:20px;background:#3fbc39;border-radius: 50%;"></span> -->
            <img src="../../assets/image/RisiSafe.png" style="width: 20px;height: 20px;"/>
            <!-- <el-tag color="#3fbc39"  effect="dark"> </el-tag> -->
            <span style="margin-right: 20px;">正常</span>
            <!-- <span style="width:20px;height:20px;background:#f6d10e;border-radius: 50%;"></span> -->
            <img src="../../assets/image/RisiWarn.png" style="width: 20px;height: 20px;"/>
            <!-- <el-tag color="#f6d10e"  effect="dark"> </el-tag> -->
            <span style="margin-right: 20px;">低风险</span>
            <!-- <span style="width:20px;height:20px;background:#fd9133;border-radius: 50%;"></span> -->
            <img src="../../assets/image/RisiWarning.png" style="width: 20px;height: 20px;"/>
            <!-- <el-tag color="#fd9133"  effect="dark"> </el-tag> -->
            <span style="margin-right: 20px;">中风险</span>
            <!-- <span style="width:20px;height:20px;background:#ff3f3f;border-radius: 50%;"></span> -->
            <img src="../../assets/image/RisiDanger.png" style="width: 20px;height: 20px;"/>
            <!-- <el-tag color="#ff3f3f"  effect="dark"> </el-tag> -->
            <span style="margin-right: 20px;">高风险</span>
          </span>
        </div>
      </div>
      <div class="right" style="height:34px;">
        <div style="display: flex" v-if="admin != '00000000-0000-0000-0000-000000000000'">
          <div>
            <add-patient :type="1" @AddSonToFather="Addfather" :project="value"></add-patient>
          </div>
          <add-label :ProjectPatientId="activityMultipleSelection[0]" @AddLabelFn="AddFn"
                     @DelLabelFn="DelFn"></add-label>
          <el-button @click="dialAll" type="primary">+AI外呼</el-button>
          <!-- <al-phone></al-phone> -->
          <el-button @click="msgFollow" type="primary">短信随访</el-button>
          <!-- <message-follow></message-follow> -->
        </div>
        <div v-if="admin != '00000000-0000-0000-0000-000000000000'">
          <input type="file" ref="file" accept=".xls, .xlsx" title @change="getFile" class="se2"/>
          <el-button @click="chooseFile" class="dao-ru">
            <img src="../../assets/image/icon/daoru.png"/>导入EXCEL
          </el-button>
          <el-button @click="TemplateDownload" class="export-img-btn">
            <img src="../../assets/image/icon/xiazai.png" alt/>
            <span>模板下载</span>
          </el-button>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="con">
        <el-table ref="multipleTable" :data="PatientTable"
                  style="width: 98%;margin: 0 auto;overflow: hidden !important;" height="100%"
                  :header-cell-style="{ 'text-align': 'center', background: 'rgb(240, 247, 253)' }" border
                  @select="selectAll"
                  @select-all="arrselectAll">
          <!-- @select-all="selectAll"
          @selection-change="handleActivitySelectionChange"-->
          <el-table-column type="selection" min-width="30" align="center"></el-table-column>
          <el-table-column v-if="admin == '00000000-0000-0000-0000-000000000000'" prop="OrganizationName"
                           label="组织名称"
                           min-width="80" align="center"></el-table-column>
          <el-table-column label="项目编号" min-width="60" align="center">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.ProjectName" placement="top">
                <p>{{ scope.row.ProjectNo }}</p>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="No" label="患者编号" min-width="60" align="center"></el-table-column>
          <el-table-column label="电话" min-width="110" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.Tel }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="RiskRating" :sortable="true" :sort-method="sortByRisk" label="风险" min-width="80"
                           align="center">
            <template slot-scope="scope">
              <!-- <el-tag
                :color="
                  scope.row.RiskRating == '01' || scope.row.RiskRating==null
                    ? '#3fbc39'
                    : scope.row.RiskRating == '02'
                    ? '#f6d10e'
                    : scope.row.RiskRating == '03'
                    ? '#fd9133'
                    : scope.row.RiskRating == '04'
                    ? '#ff3f3f'
                    : ''
                "
                effect="dark"
                disable-transitions
              ></el-tag>-->
              <img v-if="scope.row.RiskRating == '01' || scope.row.RiskRating == null"
                   src="../../assets/image/RisiSafe.png" style="width: 20px;height: 20px;"/>
              <img v-if="scope.row.RiskRating == '02'" src="../../assets/image/RisiWarn.png"
                   style="width: 20px;height: 20px;"/>
              <img v-if="scope.row.RiskRating == '03'" src="../../assets/image/RisiWarning.png"
                   style="width: 20px;height: 20px;"/>
              <img v-if="scope.row.RiskRating == '04'" src="../../assets/image/RisiDanger.png"
                   style="width: 20px;height: 20px;"/>
            </template>
          </el-table-column>
          <el-table-column prop="Name" label="姓名" :sortable="true" :sort-method="sortByVesselName" min-width="100px"
                           align="center" fixed></el-table-column>

          <el-table-column label="证件号" min-width="200" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.CertNo }}</span>
            </template>
          </el-table-column>

          <!-- <el-table-column prop="AttendingDoctor" label="主治医师" min-width="70" align="center">
          </el-table-column>-->
          <el-table-column label="性别" min-width="100" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.Sex == 1 ? '男' : '' }}</p>
              <p>{{ scope.row.Sex == 0 ? '女' : '' }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="Age" label="年龄" sortable min-width="100" align="center"></el-table-column>
          <el-table-column prop="Birthday" label="出生日期" sortable min-width="180" align="center">

          </el-table-column>
          <el-table-column prop="DischargeDiagnosis" label="出院诊断" min-width="180" align="center"></el-table-column>
          <el-table-column prop="SecondaryDiagnosis" label="次要诊断" min-width="180" align="center"></el-table-column>
          <el-table-column prop="CreateTime" label="创建时间" sortable min-width="180" align="center"></el-table-column>
          <el-table-column prop="OperationDate" label="手术日期" sortable min-width="180" align="center">
            <template #default="{ row }">
              {{ row.OperationDate?.split(" ")[0] }}
            </template>
          </el-table-column>
          <el-table-column label="患者标签" min-width="100" align="center">
            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.LabelNameList" :key="index" class="tag_span">{{ item }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="RandomNumber" label="随机号" min-width="100" align="center"></el-table-column>
          <el-table-column prop="GroupNumber" label="组别号" min-width="100" align="center"></el-table-column>
          <!-- 随访记录 -->
          <!-- <el-table-column prop="PatientLabel" label="随访详情" min-width="100" align="center" fixed="right">
             <template slot-scope="scope">
                  <el-link
                  :underline="false"
                  @click="FollowUpRecords(scope.row)"
                >随访记录</el-link>
             </template>
          </el-table-column>-->
          <!-- 患者头像 -->
          <!-- <el-table-column prop="patient" label="患者画像" width="100" align="center" fixed="right">
            <template slot-scope="scope">
              <img  @click="PatientPortrait(scope.row)" src="../../assets/image/icon/patient-touxiang.png" alt=""
              >
            </template>
          </el-table-column>-->

          <!-- <el-table-column prop="patient" label="患者画像" width="90" align="center">
            <template slot-scope="scope">
              <img  @click="PatientPortrait(scope.row)" src="../../assets/image/icon/patient-touxiang.png">
            </template
            >
          </el-table-column>-->
          <!-- 问卷填写 -->
          <!-- <el-table-column prop="PatientLabel" label="填写问卷" min-width="100" align="center"  v-if="admin!='00000000-0000-0000-0000-000000000000'" fixed="right">
             <template slot-scope="scope">
                  <el-link
                  :underline="false"
                  @click="QuestionnaireFilling(scope.row)"
                >问卷填写</el-link
                >
             </template>
          </el-table-column>-->
          <el-table-column label="操作" :min-width="!devicePixelRatio ? '250' : '140'" class="edit-info"
                           v-if="admin != '00000000-0000-0000-0000-000000000000'" fixed="right">
            <template slot-scope="scope">
              <!-- <el-link-->
              <!--                style="margin-right: 14px"-->
              <!--                :underline="false"-->
              <!--                @click="information(scope.$index, scope.row)"-->
              <!--              ><i class="el-icon-edit-outline"></i>修改</el-link -->
              <!--              > -->
              <div style="display: flex;flex-wrap: wrap;justify-content: space-between;padding: 0 10px;">
                <!-- v-has="'Patient/Edt'" -->
                <el-link :underline="false" @click="QuestionnaireFilling(scope.row)" style>
                  <div style="display: flex;align-items: center;">
                    <img style="width: 16px;height: 16px;margin-top: 2px;" src="../../assets/image/icon/FillIn2.png"
                         alt/>
                    {{ textInfo[0] ? textInfo[0].LabelName : '新增问卷' }}
                  </div>
                </el-link>
                <el-link :underline="false" @click="FollowUpRecords(scope.row)" style>
                  <div style="display: flex;align-items: center;">
                    <img style="width: 16px;height: 16px;margin-top: 3px;" src="../../assets/image/icon/takeNotes1.png"
                         alt/>
                    {{ textInfo[1] ? textInfo[1].LabelName : '随访记录' }}
                  </div>
                </el-link>
                <el-link @click="PatientPortrait(scope.row)" :underline="false" style>
                  <div style="display: flex;align-items: center;">
                    <img style="width: 16px;height: 16px;margin-top: 1px;"
                         src="../../assets/image/icon/PatientPortrait2.png" alt/>
                    患者画像
                  </div>
                </el-link>
                <el-link @click="AddPatientDialog(scope.row)" type="primary" :underline="false">
                  <i class="el-icon-edit-outline"></i>修改
                </el-link>
                <el-link :underline="false" style @click="DeletePatient(scope.row)">
                  <i class="el-icon-delete"></i>删除
                </el-link>

                <!-- <el-link  style="margin-left: 11px" @click="AddRobotIdDialog(scope.row)" type="primary" :underline="false"><i class="el-icon-phone-outline"></i>拨打</el-link> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="width: 98%;margin: 0 auto;">
        <Pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.rows"
                    @pagination="ProjectGetPageList(value)"></Pagination>
      </div>
      <add-patient :type="2" @EditSonToFather="Editfather" :item="PatientList" v-if="dialogFlag"
                   :dialog="dialogFlag"></add-patient>
      <div class="QuestionnaireTable">
        <el-dialog :visible.sync="dialogVisible" width="30%">
          <div slot="title">
            <span style="color: #fff;">问卷填写</span>
          </div>
          <div>
            <p style="font-size: 16px;margin-bottom: 10px;">问卷列表</p>
            <el-select v-model="Qval" placeholder="请选择" @change="QuestionnaireTableChange"
                       style="margin-bottom: 10px;">
              <el-option v-for="(item, index) in QuestionnaireTable" :key="index" :label="item.Name"
                         :value="item.Id"></el-option>
            </el-select>
          </div>
          <div style="width:100%" v-if="Config?.openInterviewCount">
            <p style="font-size: 16px;margin-bottom: 10px;">访视次数</p>
            <el-input v-model="CObj" placeholder="请输入内容"></el-input>
          </div>
          <div slot="footer" class="btn">
            <button @click="dialogVisible = false">取 消</button>
            <button type="primary" @click="determine">确 定</button>
          </div>
        </el-dialog>
      </div>
      <div class="QuestionnaireFilling">
        <el-dialog :visible.sync="QuestionnaireFillingDialog" fullscreen destroy-on-close>
          <iframe :src="Url" frameborder="0" width="100%" height="100%" ref="myFrame"></iframe>
        </el-dialog>
      </div>
      <el-drawer :visible.sync="detailsDrawer" direction="rtl" :wrapperClosable="false" size="1920px">
        <div slot="title" class="header-title">
          <i class="el-icon-right" style="font-size: 20px;margin-left: 16px;margin-right: 21px"
             @click="detailsDrawer = false"></i>
          <span style="margin-right: 237px">随访记录</span>
          <span>随访详情</span>
        </div>
        <div class="detailsDrawer-components">
          <task-details :ProjectObj="ProjectObj" :PObj="PObj" v-if="detailsDrawer"></task-details>
        </div>
      </el-drawer>
      <div class="QuestionnaireTable">
        <el-dialog :visible.sync="PdialogVisible" :width="devicePixelRatio ? '30%' : '60%'">
          <div slot="title">
            <span style="color: #fff;">批量导入</span>
          </div>
          <div>
            <p style="font-size: 16px;margin-bottom: 10px;">项目列表</p>
            <el-select v-model="Pvalue" placeholder="请选择">
              <el-option v-for="(item, index) in QProject" :key="index" :label="item.Name" :value="item.Id"></el-option>
            </el-select>
          </div>
          <div slot="footer" class="btn">
            <button @click="PdialogVisible = false">取 消</button>
            <button type="primary" @click="Pdetermine">确 定</button>
          </div>
        </el-dialog>
      </div>
      <div class="QuestionnaireTable">
        <el-dialog :visible.sync="robotVisible" :width="!devicePixelRatio ? '30%' : '60%'">
          <div slot="title">
            <span style="color: #fff;">拨打</span>
          </div>
          <div>
            <p style="font-size: 16px;margin-bottom: 10px;">机器人列表</p>
            <el-select v-model="obj.RobotId" placeholder="请选择" maxlength="10" minlength="2">
              <el-option v-for="(item, index) in ruleLIst" :key="index" :label="item.Name" :value="item.Id"></el-option>
            </el-select>
          </div>
          <div slot="footer" class="btn">
            <button @click="robotVisible = false">取 消</button>
            <button type="primary" @click="ExecuteOnce">确 定</button>
          </div>
        </el-dialog>
      </div>
      <!-- <div style="margin-left: 15px;margin-top: 10px">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="this.count"
          :page-size="this.num"
          :current-page="this.corr"
          @current-change="handle"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>-->
    </div>
    <el-dialog title="提示" :visible.sync="msgVisible" width="30%">
      <div slot="title">
        <span style="color: #fff;">短信随访</span>
      </div>
      <div>
        <p style="font-size: 16px;margin-bottom: 10px;">问卷列表</p>
        <el-select v-model="obj2.ProjectQuestionnaireId" placeholder="请选择" style="margin-bottom: 10px;">
          <el-option v-for="(item, index) in questionnaireList" :key="index" :label="item.Name"
                     :value="item.Id"></el-option>
        </el-select>
      </div>
      <div slot="footer" class="btn">
        <button @click="msgVisible = false">取 消</button>
        <button type="primary" @click="handleConfirm">确 定</button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import PatientCenter from "@/api/PatientCenter";
import AddLabel from "@/components/PatientList/AddLabel";
import AddPatient from "@/components/PatientList/AddPatient"; //打开新增患者
import {mapState} from "vuex";
import ProjectManagement from "../../api/ProjectManagement";
import derive from "../../api/derive.js";
import Pagination from "../../components/Pagination/index.vue";
import TaskDetails from "../../components/task/TaskDetails.vue";
import {local} from "../../utils/storage";
import Cascader from "@/components/Cascader.vue";
// import Vconsole from 'vconsole';
export default {
  data() {
    return {
      obj: {
        ProjectId: "",
        RobotId: "",
        ProjectPatientIds: [],
        Channel: "00"
      },
      obj2: {
        "ProjectId": "",
        "ProjectQuestionnaireId": "",
        "ProjectPatientIds": [],
        "Channel": "01"
      },
      robotVisible: false,
      PdialogVisible: false,
      Pvalue: "",
      admin: local.get("UserId"),
      PatientList: "",
      detailsDrawer: false,
      QuestionnaireFillingDialog: false,
      PatientTable: [],
      StartTime: "", //开始时间
      EndTime: "", //结束时间
      keyword: "", //关键字
      Excel: [],
      Project: [
        {Name: "全部", Id: "ALL", ParentId: null},
        {Name: "其他", Id: "OTHER", ParentId: null,}
      ],
      value: [["ALL"]], //项目id
      dialogVisible: false,
      QuestionnaireTable: [],
      ItemList: [],
      Qval: "",
      PObj: "", //储存患者对象
      CObj: "", //随访次数对象
      Url: "",
      ProjectObj: {},
      total: 0,
      listQuery: {
        Key: "",
        page: 1,
        rows: 10,
      },
      activityMultipleSelection: [],
      ruleLIst: [], //机器人列表,
      QProject: [],
      border: 0,
      devicePixelRatio: false,
      direction: false,
      flag: false,
      scrollTop: 0,
      scrollLeft: 0,
      Config: {},
      textInfo: [],
      msgVisible: false,
      questionnaireList: [],
    };
  },
  computed: mapState(["dialogFlag"]),
  mounted() {
    this.devicePixelRatio = document.documentElement.clientWidth < 1100;
  },
  created() {
    // this.value = {
    //   Id: "ALL",
    //   Name: "全部",
    // };
    this.ProjectList();
    this.GetOrder()
  },
  components: {
    Cascader,
    AddLabel,
    AddPatient,
    TaskDetails,
    Pagination,
  },
  methods: {
    sortByVesselName(obj1, obj2) {
      if (
          [obj1.Name, obj2.Name][0] ==
          [obj1.Name, obj2.Name].sort((a, b) =>
              a.localeCompare(b, "zh", {sensitivity: "accent"})
          )[0]
      ) {
        return -1;
      } else {
        return 1;
      }
    },
    sortByRisk(obj1, obj2) {
      if (obj2.RiskRating > obj1.RiskRating) {
        return 1;
      } else {
        return -1;
      }
      // console.log(obj1.RiskRating)
    },
    dialAll() {
      if (this.obj.ProjectId == "") {
        this.border = 1;
        return this.$message({
          message: "请先在被选中的红色框中选择一个项目",
          type: "warning",
        });
      }
      if (this.activityMultipleSelection.length == 0) {
        return this.$message({
          message: "请先勾选一个患者",
          type: "warning",
        });
      }
      //新增打开对话框清空obj.RobitId
      this.obj.RobotId = "";
      this.GetRobotList(this.obj.ProjectId);
      this.robotVisible = true;
    },
    msgFollow() {
      console.log("this.obj.ProjectId---", this.obj.ProjectId)
      // if (this.obj.ProjectId == "") {
      //   this.border = 1;
      //   return this.$message({
      //     message: "请先在被选中的红色框中选择一个项目",
      //     type: "warning",
      //   });
      // }
      if (this.activityMultipleSelection.length == 0) {
        return this.$message({
          message: "请先勾选一个患者",
          type: "warning",
        });
      }
      let params = []
      this.value.forEach((item => params.push(item.slice(-1)[0])))
      this.msgVisible = true

      let param = {
        "ProjectId": this.flatProjectId(this.value)
      }
      derive.CRFSurfaceData(param).then((res) => {
        if (res.data.Status == 1) {
          this.questionnaireList = res.data.Data;
          console.log('问卷列表----', res.data)
        } else {
          alert(res.data.Message);
        }
      });
    },
    handleConfirm() {
      if (this.obj2.ProjectQuestionnaireId == "") {
        return this.$message({
          message: "请选择一张问卷",
          type: "warning",
        });
      }
      this.activityMultipleSelection.forEach((item) => {
        this.PatientTable.forEach((p) => {
          if (p.Id == item.Id) {
            this.obj2.ProjectPatientIds.push(p.Id);
          }
        });
      });
      this.obj2.ProjectId = this.value[0][0]
      console.log(this.obj2);
      ProjectManagement.ExecuteOnceData(this.obj2).then((res) => {
        if (res.data.Status == 1) {
          // this.ProjectList();
          this.$message({
            type: "success",
            message: res.data.Message,
          });
          this.msgVisible = false;
        } else {
          alert(res.data.Message);
        }
      });
    },
    TemplateDownload() {
      ProjectManagement.TemplateDownloadData().then((res) => {
        if (res.data.Status == 1) {
          this.downLoad(res.data.Data);
        } else {
          alert(res.data.Message);
        }
        console.log(res);
      });
    },
    downLoad(record) {
      // console.log('record.httpAddress: ', record.httpAddress)
      const link = document.createElement("a");
      //_blank表示在新窗口打开链接
      link.target = "_blank";
      link.href = record;
      document.body.appendChild(link);
      link.click();
      // document.body.removeChild(link) // 下载完成移除元素
    },
    AddRobotIdDialog(row) {
      console.log(row);
      this.obj.ProjectId = row.ProjectId;
      this.obj.ProjectPatientId = row.Id;
      this.GetRobotList(row.ProjectId);
      this.robotVisible = true;
    },
    //拨打
    ExecuteOnce() {
      if (this.obj.RobotId == "") {
        return this.$message({
          message: "请选择一个机器人",
          type: "warning",
        });
      }
      this.activityMultipleSelection.forEach((item) => {
        this.PatientTable.forEach((p) => {
          if (p.Id == item.Id) {
            this.obj.ProjectPatientIds.push(p.Id);
          }
        });
      });
      ProjectManagement.ExecuteOnceData(this.obj).then((res) => {
        console.log(res);
        if (res.data.Status == 1) {
          this.ProjectList();
          this.$message({
            type: "success",
            message: "拨打成功!",
          });
          this.robotVisible = false;
        } else {
          alert(res.data.Message);
        }
      });
    },
    //机器人列表
    GetRobotList(ProjectId) {
      ProjectManagement.GetRobotListData(ProjectId).then((res) => {
        if (res.data.Status == 1) {
          this.ruleLIst = res.data.Data;
        } else {
          alert(res.data.Message);
        }
        console.log(res, "机器人");
      });
    },
    async AddPatientDialog(row) {
      let res = await derive.PatientCenterGet(row.Id)
      this.PatientList = res.data.Data;
      this.$store.commit("dialogFlagFn", true);
    },
    PatientPortrait(row) {
      console.log(row);
      this.$router.push({
        path: "/PatientPortrait",
        query: {Id: this.$route.query.Id, qId: row.Id},
      });
    },
    DelFn(data) {
      console.log(data, "%%%");
      this.PatientTable.forEach((item) => {
        if (item.Id == data.ProjectPatientId) {
          item.LabelNameList = item.LabelNameList.filter((row) => {
            return row != data.Name;
          });
        }
      });
    },
    AddFn(data) {
      this.PatientTable.forEach((row) => {
        if (row.Id == data.ProjectPatientId) {
          row.LabelNameList.push(data.Name);
        }
      });
    },
    //选中方法
    // handleActivitySelectionChange(selection){
    //     //this.activityMultipleSelection自己定义的接受数组
    //   this.activityMultipleSelection = selection
    //   if (selection.length > 1) {
    //     let del_row = selection.shift()
    //     this.$refs.multipleTable.toggleRowSelection(del_row, false)
    //   }
    // },
    //判断选中数量
    selectAll(selection) {
      console.log(selection);
      this.obj.ProjectPatientIds = [];
      this.activityMultipleSelection = selection;
      //  selection.forEach(item=>{
      //     this.obj.ProjectPatientIds.push(item.Id)
      //  })
    },
    arrselectAll(selection) {
      this.obj.ProjectPatientIds = [];
      this.activityMultipleSelection = selection;
    },
    //打开随访记录弹框
    FollowUpRecords(row) {
      console.log(row);
      this.PObj = row;
      this.$store.commit("SubmenuFn", true);
      this.detailsDrawer = true;
    },
    //userId="患者id"
    //userName="患者名字"
    //fillinId="登录人id"
    //fillinName="登录人姓名"
    //phone="电话"
    //visitsNumber="方式次数"
    determine() {
      if (this.Qval != "") {
        console.log(this.Qval);
        console.log(this.CObj);
        // let Frq=null
        // if(this.CObj.Frq==null){
        //     Frq=0
        // }else{
        //     Frq=this.CObj.Frq
        // }
        this.QuestionnaireFillingDialog = true;
        this.dialogVisible = false;
        // this.Url = `${window.global_config.BASE_URL1}/#/P?sysData=${this.PObj.CallBackInfo.CallBackParameterEncrypt}&type=1&qid=${this.Qval}&userId=${this.PObj.Id}&userName=${this.PObj.Name}&fillinId=${local.get("UserId")}&fillinName=${local.get("UserName")}&phone=${this.PObj.Tel}&visitsNumber=${this.CObj}&timestamp=${new Date().getTime()}`;
        if (!this.Config?.openInterviewCount) this.CObj = 0
        this.Url = `${window.global_config.BASE_URL1}/#/PcIframe?sysData=${this.PObj.CallBackInfo.CallBackParameterEncrypt}&type=4&qid=${this.Qval}&userId=${this.PObj.Id}&userName=${this.PObj.Name}&fillinId=${local.get("UserId")}&fillinName=${local.get("UserName")}&phone=${this.PObj.Tel}&visitsNumber=${this.CObj}&timestamp=${new Date().getTime()}`;
        console.log(this.PObj.Id, this.PObj.Name, this.PObj.Tel, this.CObj);
        // console.log(this.Url);
        // console.log(this.Url);
        // const parameter={
        //     ProjectId:this.PObj.ProjectId
        // }
        // console.log(this.PObj);
        // questionnaire.CallBackQuestionnaireData(parameter).then(res=>{
        //             console.log(res,"xxxxx");
        //             // let a=JSON.parse(res.data.Data)
        //             // console.log(a.ProjectId);
        //             if(res.data.Status==1){
        //                   this.QuestionnaireFillingDialog=true
        //                   this.dialogVisible=false
        //                   this.Url=`${window.global_config.BASE_URL1}/#/P?sysData=${res.data.Data}&type=1&qid=${this.Qval}&userId=${this.PObj.Id}&userName=${this.PObj.Name}&fillinId=${local.get('UserId')}&fillinName=${local.get('UserName')}&phone=${this.PObj.Tel}&visitsNumber=${this.CObj}`
        //             }else{
        //                 alert(res.data.Message)
        //             }
        // })
      }
    },
    //选择问卷后调用随访接口
    QuestionnaireTableChange(e) {
      let item = this.QuestionnaireTable.find(i => i.Id === e)
      if (typeof item.Config === 'string') {
        item.Config = JSON.parse(item.Config)
      }
      this.Config = item.Config
      if (e.issueState == false) {
        this.Qval = "";
        alert("请先去发布问卷");
      }
    },
    flatProjectId(ProjectId) {
      let arr = []
      ProjectId.forEach(item => {
        arr.push(item.slice(-1)[0])
      })
      return arr
    },
    //项目选择后的回调
    ProjectChange(item) {
      this.obj.ProjectId = "";
      // if (item.Id == "ALL" || item.Id == "OTHER") {
      //   local.set("IsRtc", true);
      // } else {
      //   this.obj.ProjectId = item.Id;
      //   this.obj2.ProjectId = item.Id;
      //   local.set("IsRtc", item.IsRtc);
      // }
      this.ProjectGetPageList(item);
    },
    //项目
    async ProjectList() {
      derive.ProjectListData().then((res) => {
        console.log(res);
        if (res.data.Status == 1) {
          if (res.data.Data.length != 0) {
            this.QProject = JSON.parse(JSON.stringify(res.data.Data));

            this.Project.push(...res.data.Data)

            // let Objall={
            //     Name:"全部",

            //     Id:"all",
            // }
            // let ObjOther={
            //     Name:"其他",
            //     Id:"OTHER",
            // }
            this.ProjectGetPageList(this.value);
          }
        } else {
          alert(res.data.Message);
        }
      });
    },
    convertToTree(flatArray) {
      const map = {};
      const tree = [];

      // 构建映射
      flatArray.forEach(item => {
        map[item.Id] = {...item, children: []};
      });

      // 构建树形结构
      flatArray.forEach(item => {
        if (item.ParentId !== null && map[item.ParentId]) {
          map[item.ParentId].children.push(map[item.Id]);
        } else if (item.ParentId === null) {
          tree.push(map[item.Id]);
        }
      });

      return tree;
    },
    //打开问卷填写
    QuestionnaireFilling(row) {
      console.log(row);
      // console.log(row);
      const h = this.$createElement;
      this.$confirm("", {
        message: h("div", null, [
          h("i", {
            class: "el-icon-question",
            style: "color:#f90;font-size:30px;",
          }),
          h(
              "span",
              {
                style:
                    "margin-left:10px;font-size:16px;line-height:30px;font-weight:600;vertical-align:top;",
              },
              "提示"
          ),
          h(
              "p",
              {style: "margin:10px 0 0 40px;font-size:20px"},
              "是否新增一份问卷吗？"
          ),

          h(
              "p",
              {
                style: "text-align: center;line-height:18px;position: absolute;bottom:-110px",
              },
              [
                h("span", {style: "color:red;font-weight:800"}, "温馨提示: "),
                h(
                    "span",
                    {},
                    "如果想要继续填写“已填写过的问卷”，请到“已收集问卷”或“随访记录”中继续填写即可!"
                ),
              ]
          ),
        ]),
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "diyModel",
        closeOnClickModal: false,
        closeOnPressEscape: false,
      }).then(() => {
        console.log(row, 'row')
        if (!row.CallBackInfo.IsSuccess) {
          alert(row.CallBackInfo.Msg);
          return;
        }
        if (row.ProjectId == null) {
          alert("该患者不属于任何项目,无法填写问卷");
        } else {
          this.PObj = row;
          this.dialogVisible = true;
          this.Url = "";
          this.Qval = "";
          this.QuestionnaireGetPageList(row.ProjectId);
        }
      });
    },
    //问卷列表分页
    QuestionnaireGetPageList(ProjectId) {
      const parameter = {
        ProjectId: [ProjectId],
      };
      derive.CRFSurfaceData(parameter).then((res) => {
        if (res.data.Status == 1) {
          this.QuestionnaireTable = res.data.Data;
          if (this.QuestionnaireTable.length === 1) {
            this.Qval = this.QuestionnaireTable[0].Id;
          }
        } else {
          alert(res.data.Message);
        }
      });
    },
    //重置
    Reset() {
      // this.value.Id = 'all';
      // this.value.Name = '全部';
      this.value = [['ALL']];
      this.listQuery.Key = "";
      this.keyword = "";
      this.StartTime = "";
      this.EndTime = "";
      this.ProjectGetPageList([["ALL"]]);
    },
    //搜索GetPageList
    query() {
      this.listQuery.Key = this.keyword;
      this.listQuery.page = 1;
      this.ProjectGetPageList(this.value);
    },
    //打开导入
    chooseFile() {
      this.$refs.file.value = "";
      this.Pvalue = "";
      this.PdialogVisible = true;
    },
    Pdetermine() {
      this.PdialogVisible = false;
      this.$refs.file.click();
    },
    // 思路：对于原数组每个对象来说
    //    （1） 找出所有的中文key
    //     (2)  得到对应的英文key
    //     (3)  拼接一个新对象： 英文key:值
    transExcel(results) {
      // 首先需要一个我们写好的用来映射的对象或数组
      const mapInfo = {
        患者编号: "No",
        医保卡号: "MiCardNo",
        "患者姓名（必填）": "Name",
        名字缩写: "NameAbbr",
        "就诊日期（日期格式2020-09-25）": "VisitDate",
        "患者电话（必填）": "Tel",
        "证件类型（00=身份证 01=军官证 02=护照 03=其它）请填写对应的数字":
            "CertType",
        证件号码: "CertNo",
        "出生日期（日期格式2020-09-25）": "Birthday",
        年龄: "Age",
        "性别（1=男 0=女）请填写对应的数字": "Sex",
        "身高（m）": "Height",
        "体重（kg）": "Weight",
        "教育程度（00=文盲 01=小学 02=初中 03=中专及高中 04=大专 05=本科 06=硕士及以上）请填写对应的数字":
            "Edu",
        患者微信: "WX",
        患者QQ: "QQ",
        "紧急联系人姓名（必填）": "EcName",
        紧急联系人电话: "EcTel",
        "风险等级（01=正常 02=低风险 03=中风险 04=高风险）请填写对应的数字":
            "RiskRating",
        住院病区: "Ward",
        住院天数: "Day",
        "出院日期（日期格式2020-09-25）": "DischargeDate",
        出院诊断: "DischargeDiagnosis",
        "出院方式（00=同意出院 01=自动出院 02=转院 03=逃逸出院）请填写对应的数字":
            "DischargeMode",
        主治医生: "AttendingDoctor",
        随访医生: "FollowUpDoctor",
        随机号: "RandomNumber",
        组别号: "GroupNumber",
        "患者来源（00=测试来源1 02=测试来源2 03=测试来源3）请填写对应的数字":
            "Source",
        患者地址: "Address",
      };
      return results.map((zhObj) => {
        // 首先创建一个空对象来逐个接收转换后的key
        const enObj = {};

        // 使用Object.keys方法来获取中文的key
        const zhKeys = Object.keys(zhObj); // ['姓名', '手机号']

        // 用forEach循环来将key逐个映射(map)出去
        zhKeys.forEach((zhKey) => {
          const enKey = mapInfo[zhKey]; // 如果是一个对象具体的某个属性,就用 对象.(属性),此处非确切值,则为 对象[属性] 写法

          enObj[enKey] = zhObj[zhKey]; // 将空对象所需要接收的值传入
        });

        return enObj;
      });
    },
    //导入
    getFile(e) {
      console.log(e, "dwaaaaaaaaaaaa");
      let fd = new FormData();
      fd.append("file", e.target.files[0]);
      fd.append("ProjectId", this.Pvalue);
      ProjectManagement.PatientExportData(fd).then((res) => {
        if (res.data.Status == 1) {
          this.$message({
            type: "success",
            message: res.data.Message,
          });
          this.ProjectList();
        } else {
          alert(res.data.Message);
        }
        console.log(res);
      });
    },
    //编辑
    Editfather(data) {
      this.PatientTable = this.PatientTable.map((item) => {
        if (item.Id == data.Id) {
          item = data;
        }
        return item;
      });
    },
    //新增患者
    Addfather(data) {
      console.log(data, "%%%%%%%%");
      // this.PatientTable.unshift(data);
      // this.ProjectGetPageList(this.value.Id);
      this.ProjectList();
    },
    //项目患者列表
    ProjectGetPageList(Id) {
      Id = this.flatProjectId(Id)
      const parameter = {
        projectId: Id,
        Key: this.listQuery.Key,
        page: this.listQuery.page,
        rows: this.listQuery.rows,
        BeginTime: this.StartTime,
        EndTime: this.EndTime,
      };
      ProjectManagement.QGetPageListData(parameter).then((res) => {
        console.log(res, "项目患者列表");
        if (res.data.Status == 1) {
          this.PatientTable = res.data.Data.rows.map((item) => {
            item.CreateTime = item.CreateTime.substring(0, 10);
            if (item.Birthday) {
              item.Birthday = item.Birthday.substring(0, 10);
            }
            return item;
          });
          this.total = res.data.Data.total;
          this.$nextTick(() => {
            this.activityMultipleSelection.forEach((row) => {
              this.PatientTable.forEach((p) => {
                if (p.Id == row.Id) {
                  this.$refs.multipleTable.toggleRowSelection(p);
                }
              });
            });
          });
          console.log(this.activityMultipleSelection, "成功");
        } else {
          alert(res.data.Message);
        }
      });
    },
    //删除患者
    DeletePatient(row) {
      const parameter = [];
      parameter.push(row.Id);
      this.$confirm("删除后将无法恢复，请问你确定要删除该患者吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        PatientCenter.DeletePatientData(parameter).then((res) => {
          console.log(res);
          if (res.data.Status == 1) {
            this.PatientTable = this.PatientTable.filter((item) => {
              return item.Id != row.Id;
            });
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.ProjectGetPageList(this.value.Id);
          } else {
            alert(res.data.Message);
          }
        });
      });
    },
    async GetOrder() {
      let res = await PatientCenter.GetOrder({type: 'T'})
      if (res.data.Status !== 1) return this.$message.error(res.data.Message)
      this.textInfo = res.data.Data
    }
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/mixin.scss";

// ::v-deep .el-link--inner {
//   margin-right: 11px;
// }


.el-table__fixed-right {
  right: 0px !important;
  background-color: #fff;
}

.classborder {
  border: 1px solid red;
}

.tag_span {
  display: inline-block;
  min-width: 80px;
  height: 26px;
  background-color: #409eff;
  border: 1px solid #b3d8ff;
  border-radius: 4px;
  line-height: 24px;
  // color: #409eff;
  color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 8px;
  margin-left: 4px;
  font-size: 12px;
}

.QuestionnaireFilling {
  ::v-deep .el-dialog__body {
    height: 94%;
    padding: 0;
  }

  ::v-deep .el-dialog__headerbtn {
    top: 8px;
  }
}

.span-h {
  cursor: pointer;
}

.QuestionnaireTable {
  width: 100%;
}

.el-select {
  width: 100%;
}

.QuestionnaireTable {
  ::v-deep .el-input__inner {
    // width: 100%;
    // min-width: 400px;
    // width: 540px;
    height: 40px;
    line-height: 40px;
  }

  ::v-deep .el-input {
    height: 40px;
  }
}

::v-deep .el-input {
  height: 30px;
}

::v-deep .el-input__inner {
  width: 100%;
  height: 30px;
}

::v-deep .el-form-item__content {
  height: 34px;
}

::v-deep .el-button {
  height: 34px;
  font-weight: 400;
  @include add-size5($font_size_14);
}

::v-deep .el-button--primary {
  @include common_button_primary;
  margin-left: 20px;
}

::v-deep .el-input__icon {
  line-height: 20px;
  width: 20px;
}

.tolead {
  width: 100%;
  min-width: 1440px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .title {
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .left {
      margin-top: 14px;
      margin-bottom: 1%;
      margin-left: 1%;
      width: 99%;
      display: flex;

      // justify-content: center;
      // align-items: center;
      .yuan {
        display: flex;
        align-items: center;
        height: 34px;
        // ::v-deep .el-tag {
        //   border-radius: 50% !important;
        //   width: 20px;
        //   height: 20px;
        //   vertical-align: middle;
        // }
        float: right;
        margin-right: 20px;

        span {
          @include common_span;
        }
      }

      span {
        display: inline-block;
        @include common_span;
        margin-left: 6px;
        margin-right: 6px;
      }

      ::v-deep .el-input__inner {
        height: 34px;
        line-height: 20px;
        border-radius: 4px;
        @include add-size($font_size_16);
      }

      ::v-deep .el-input {
        margin-right: 10px;
        // width: 200px;
        height: 34px;
      }

      ::v-deep .el-input__inner {
        height: 34px;
      }

      ::v-deep .el-button.czhi {
        @include common_button_plain;
      }
    }

    .right {
      width: 98%;
      margin: 0 auto 10px auto;
      display: flex;
      justify-content: space-between;

      //position: relative;
      ::v-deep .el-button.export-img-btn {
        @include common_button_plain;
      }

      ::v-deep .el-button.dao-ru {
        @include common_button_primary;

        img {
          vertical-align: 0;
        }
      }

      .se2 {
        display: none;
      }
    }
  }

  .wenid {
    .wenidselect {
      width: 300px;
      height: 30px;
    }
  }
}

.con {
  height: 86%;
  margin-bottom: 10px;

  ::v-deep .el-tag {
    border-radius: 50% !important;
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  .fen-rigth {
    width: 40%;

    input {
      width: 30px;
      height: 26px;
      margin-left: 1%;
      margin-right: 1%;
      border: 0;
      text-align: center;
      border-radius: 10%;
      font-size: 16px;
    }

    .span1-rigth {
      color: rgb(19, 104, 171);
    }
  }
}

.content {
  height: 700px;
  padding-top: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  height: 100%;
}

.box1 {
  width: 50%;
  height: 40px;

  .box1-span1 {
    width: 36%;
    display: inline-block;
    text-align: right;
  }

  .box1-span2 {
    width: 56%;
    display: inline-block;
    margin-left: 2%;

    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 180px;
    }

    ::v-deep .el-input {
      width: 85%;
    }

    ::v-deep .el-input__inner {
      width: 97%;
      height: 28px;
    }

    ::v-deep .el-input__icon {
      line-height: 28px;
    }

    ::v-deep .el-radio-group .el-radio {
      width: 38px;

      .el-radio__label {
        padding-left: 4%;
      }
    }

    ::v-deep .el-radio {
      margin-right: 0;
    }

    ::v-deep #select {
      width: 104px;
    }

    ::v-deep #select1 {
      width: 104px;
    }
  }

  input {
    height: 26px;
    border-radius: 6px;
    outline: none;
  }
}

.box22 {
  width: 100%;

  .box1-span1 {
    width: 18%;
  }

  .box1-span2 {
    width: 80%;
    margin-left: 1%;

    input {
      width: 160px;
    }

    ::v-deep .el-input {
      width: 95%;
    }
  }
}

.box1btn {
  width: 94%;

  ::v-deep .el-button {
    margin-right: 1%;
    float: right;
  }
}

a {
  color: #3fbc39;
}

//.add_dialog {
//  ::v-deep .el-dialog{
//    .el-dialog__header {
//      margin-bottom: 0;
//    }
//    ::v-deep .el-form {
//    .el-input {
//        width: 360px;
//      }
//    .el-input__inner {
//        width: 360px;
//      }
//    .el-select {
//        .el-input__inner {
//          width: 360px;
//        }
//      }
//    }
//    .btn {
//      margin-top: 10px;
//    }
//  }
//}
.call-phone {
  ::v-deep .el-dialog {
    .el-dialog__body {
      padding: 0;
    }

    .el-dialog__header {
      width: 0;
      height: 0;
      padding: 0;
    }
  }
}

::v-deep .el-drawer {
  height: 100%;

  .el-drawer__header {
    padding: 0 20px 0 0;
    margin: 0;
  }

  .detailsDrawer-components {
    background: #f3f4f5;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
  }

  .header-title {
    width: 1201px;
    height: 60px;
    background: #ffffff;
    opacity: 1;
    line-height: 60px;
  }
}

::v-deep .el-button.yijian {
  @include common_button_plain;
  background: rgba(57, 120, 231, 0.15);
  border: 1px solid rgba(51, 136, 255, 0.5686274509803921);
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .tolead {
    min-width: 0 !important;

    .title {
      ::v-deep .el-button--primary {
        margin-left: 0 !important;
        margin-right: 10px !important;
      }

      .left {
        flex-wrap: wrap;
        justify-content: space-between;

        .project {
          width: 150px !important;
          margin-right: 0 !important;
        }

        .timer {
          span {
            width: 40px !important;
          }

          ::v-deep .el-input {
            width: 143px !important;
          }
        }

        .KeyWord {
          width: 150px !important;

          ::v-deep .el-input {
            margin-right: 0 !important;
          }

          span {
            white-space: nowrap;
          }

          .el-input {
            width: none !important;
          }
        }

        .yuan {
          justify-content: flex-end;
          margin-right: 0 !important;
          width: 100% !important;
        }
      }
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;

      .con {
        height: 90% !important;

        ::v-deep .el-table__fixed-right-patch {
          width: 9px !important;
          background-color: rgb(240, 247, 253) !important;
        }
      }

      .robotVisibledia {
        width: 60% !important;
      }

      // ::v-deep .el-dialog{
      //
      // }

      .QuestionnaireTable {
        ::v-deep .el-dialog {
          width: 60% !important;
        }
      }
    }

    ::v-deep .el-drawer {
      width: 100% !important;

      .detailsDrawer-components {
        height: 100%;

        .task-details-right {
          flex: 1;
        }
      }
    }
  }
}
</style>
