// 患者中心接口
import axios from "./Interceptor.js"
let address = window.global_config.BASE_URL2
let apiPatientCenter = {
    BaseDataBigData: (type) => { // 基础数据
        return axios({
            method: "get",
            url: `${address}BaseData/Get`,
            params: { type: type }
        })
    },
    AddPatientData: (info) => { // 新增患者
        return axios({
            method: "post",
            url: `${address}Patient/Add`,
            data: info
        })
    },
    GetPageListData: (info) => { // 查询患者列表分页
        return axios({
            method: "post",
            url: `${address}Patient/GetPageList`,
            data: info
        })
    },
    NothingGetPageListData: (info) => { // 查询患者列表无分页
        return axios({
            method: "post",
            url: `${address}PatientCenter/GetList`,
            data: info
        })
    },
    DeletePatientData: (info) => { // 删除患者
        return axios({
            method: "post",
            url: `${address}PatientCenter/Delete`,
            data: info
        })
    },
    EditPatientData: (info) => { // 编辑患者
        return axios({
            method: "post",
            url: `${address}Patient/Edit`,
            data: info
        })
    },
    BlankQuestionnaireData: (info) => { //项目患者未填写问卷访视次数
        return axios({
            method: "post",
            url: `${address}Project/Patient/BlankQuestionnaire/GetList`,
            data: info
        })
    },
    AddProjectLabelPatientData: (info) => { // 添加患者标签
        return axios({
            method: "post",
            url: `${address}Project/Patient/Label/Add`,
            data: info
        })
    },
    DeleteProjectLabelPatientData: (info) => { // 删除患者标签
        return axios({
            method: "post",
            url: `${address}Project/Patient/Label/Delete`,
            data: info
        })
    },
    GetListProjectLabelPatientData: (info) => { // 患者标签
        return axios({
            method: "post",
            url: `${address}Project/Patient/Label/GetList`,
            data: info
        })
    },
    GetOrder: (data) => { // 患者标签
        return axios({
            method: "post",
            url: `${address}/EntityConfig/GetList`,
            data
        })
    },
    GetOrderPage: (data) => { // 患者标签
        return axios({
            method: "post",
            url: `${address}/EntityConfig/GetPageList`,
            data
        })
    },
    updateOrder: (data) => {
        return axios({
            method: "post",
            url: `${address}/EntityConfig/Edit`,
            data
        })
    },
    // AddLabelPatientData: (info) => { // 添加患者
    //     return axios({
    //         method: "post", 
    //         url: `${address}api/Project/Patient/AddLabel`,
    //         data:info
    //     })
    // },
    GetExtendList: (data) => {
        return axios({
            method: "post",
            url: `${address}/Organization/GetExtendList`,
            data
        })
    },
    AddExtend: (data) => {
        return axios({
            method: "post",
            url: `${address}/Organization/AddExtend`,
            data
        })
    },
    editExtend: (data) => {
        return axios({
            method: "post",
            url: `${address}/Organization/EditExtend`,
            data
        })
    },
    deleteExtend: (data) => {
        return axios({
            method: "post",
            url: `${address}/Organization/DeleteExtend`,
            data
        })
    },
}
export default apiPatientCenter
