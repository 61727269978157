<template>
  <div class="tolead">
    <div class="title">
      <div class="left">
        <span>关键字</span>
        <el-input type="text" v-model="listQuery.Key"/>
        <div style="display: inline-block">
          <span>开始时间</span>
          <el-date-picker v-model="date1" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" size="small">
          </el-date-picker>
          <span>结束时间</span>
          <el-date-picker v-model="date2" type="date" size="large" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </div>
        <el-button icon="el-icon-search" type="primary" @click="search">查询</el-button>
        <el-button class="czhi" @click="Reset"><img src="../../assets/image/icon/czhi.png" alt=""
                                                    style="width: 14px;height: 14px">重置
        </el-button>
        <span class="yuan">
          <!-- <el-tag color="#3fbc39" effect="dark"> </el-tag> -->
          <img src="../../assets/image/RisiSafe.png" style="width: 20px;height: 20px;">
          <span>正常</span>
          <!-- <el-tag color="#f6d10e" type="info" effect="dark"> </el-tag> -->
          <img src="../../assets/image/RisiWarn.png" style="width: 20px;height: 20px;">
          <span>低风险</span>
          <!-- <el-tag color="#fd9133" type="warning" effect="dark"> </el-tag> -->
          <img src="../../assets/image/RisiWarning.png" style="width: 20px;height: 20px;">
          <span>中风险</span>
          <!-- <el-tag color="#ff3f3f" type="danger" effect="dark"> </el-tag> -->
          <img src="../../assets/image/RisiDanger.png" style="width: 20px;height: 20px;">
          <span>高风险</span>
        </span>
      </div>
      <div class="right">
        <div style="display: flex" v-if="admin != '00000000-0000-0000-0000-000000000000'">
          <add-patient :type="1" @AddSonToFather="Addfather"></add-patient>
          <!--        <el-button type="primary">+加入项目</el-button>-->
          <add-label :ProjectPatientId='obj.ProjectPatientIds[0]' @AddLabelFn="AddFn" @DelLabelFn="DelFn"></add-label>
          <el-button @click="dialAll" type="primary">+AI外呼</el-button>
        </div>
        <div v-if="admin != '00000000-0000-0000-0000-000000000000'">
          <input type="file" accept=".xls,.xlsx" title="" @change="getFile" class="se2"/>
          <el-button class="dao-ru"><img src="../../assets/image/icon/daoru.png" alt="">导入EXCEL</el-button>
          <el-button @click="TemplateDownload" class="export-img-btn"><img src="../../assets/image/icon/xiazai.png"
                                                                           alt="">
            <span>模板下载</span>
          </el-button>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="con">
        <el-table :data="ProjectTable" style="width: 98%;margin: 0 auto;" height="420px"
                  :header-cell-style="{ 'text-align': 'center', background: 'rgb(240, 247, 253)' }" border
                  @selection-change="selectAll">
          <el-table-column type="selection" min-width="30" align="center"></el-table-column>
          <el-table-column v-if="admin == '00000000-0000-0000-0000-000000000000'" prop="OrganizationName"
                           label="组织名称"
                           min-width="80" align="center">
          </el-table-column>

          <el-table-column label="项目编号" min-width="58" align="center">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.ProjectName" placement="top">
                <p>{{ scope.row.ProjectNo }}</p>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="No" label="患者编号" min-width="58" align="center">
          </el-table-column>
          <el-table-column prop="Tel" label="电话" min-width="120" align="center">
          </el-table-column>

          <el-table-column label="风险" min-width="48" align="center" :sortable="true" :sort-method="sortByRisk">
            <template slot-scope="scope">
              <!-- <el-tag
                :color="
                  scope.row.RiskRating == '01'||scope.row.RiskRating==null
                    ? '#3fbc39'
                    : scope.row.RiskRating == '02'
                    ? '#f6d10e'
                    : scope.row.RiskRating == '03'
                    ? '#fd9133'
                    : scope.row.RiskRating == '04'
                    ? '#ff3f3f'
                    : ''
                "
                effect="dark"
                disable-transitions
              ></el-tag> -->
              <img v-if="scope.row.RiskRating == '01' || scope.row.RiskRating == null"
                   src="../../assets/image/RisiSafe.png" style="width: 20px;height: 20px;">
              <img v-if="scope.row.RiskRating == '02'" src="../../assets/image/RisiWarn.png"
                   style="width: 20px;height: 20px;">
              <img v-if="scope.row.RiskRating == '03'" src="../../assets/image/RisiWarning.png"
                   style="width: 20px;height: 20px;">
              <img v-if="scope.row.RiskRating == '04'" src="../../assets/image/RisiDanger.png"
                   style="width: 20px;height: 20px;">
            </template>
          </el-table-column>
          <el-table-column prop="Name" label="姓名" min-width="70" align="center" fixed :sortable="true"
                           :sort-method="sortByName">
          </el-table-column>

          <!-- <el-table-column prop="HospitalNumber" label="住院号" min-width="70" align="center">
          </el-table-column> -->
          <el-table-column label="性别" min-width="48" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.Sex == 1 ? '男' : '' }}</p>
              <p>{{ scope.row.Sex == 0 ? '女' : '' }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="Age" label="年龄" min-width="60" align="center">
          </el-table-column>
          <el-table-column prop="Birthday" label="出生日期" min-width="110" align="center" :sortable="true">
          </el-table-column>
          <el-table-column prop="DischargeDiagnosis" label="出院诊断" min-width="110" align="center">
          </el-table-column>
          <el-table-column prop="SecondaryDiagnosis" label="次要诊断" min-width="110" align="center">
          </el-table-column>
          <el-table-column prop="CreateTime" label="创建时间" min-width="120" align="center" :sortable="true">
          </el-table-column>
          <el-table-column prop="OperationDate" label="手术日期" sortable min-width="180" align="center">
            <template #default="{ row }">
              {{ row.OperationDate?.split(" ")[0] }}
            </template>
          </el-table-column>
          <el-table-column label="患者标签" min-width="100" align="center">
            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.LabelNameList" :key="index" class="tag_span">
                {{ item }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="RandomNumber" label="随机号" min-width="110" align="center">
          </el-table-column>
          <el-table-column prop="GroupNumber" label="组别号" min-width="110" align="center">
          </el-table-column>
          <!-- 随访详情 -->
          <!-- <el-table-column prop="status" label="随访详情" min-width="80" align="center" fixed="right">
            <template slot-scope="scope">
              <el-link :underline="false" @click="FollowUpRecords(scope.row)"
              >随访记录</el-link>
            </template>
          </el-table-column> -->
          <!-- 患者画像 -->
          <!-- <el-table-column prop="patient" label="患者画像" width="90" align="center" fixed="right">
            <template slot-scope="scope">
              <img  @click="PatientPortrait(scope.row)"
                    src="../../assets/image/icon/patient-touxiang.png" alt=""
              >
            </template>
          </el-table-column> -->
          <!-- 填写问卷 -->
          <!-- <el-table-column prop="records" label="填写问卷" min-width="90" align="center" fixed="right" v-if="admin!='00000000-0000-0000-0000-000000000000'">
            <template slot-scope="scope">
              <el-link :underline="false" @click="QuestionnaireFilling(scope.row)"
              >问卷填写</el-link>
            </template>
          </el-table-column> -->
          <el-table-column label="操作" :width="!devicePixelRatio ? '250' : '120'" class="edit-info"
                           v-if="admin != '00000000-0000-0000-0000-000000000000'" fixed="right">
            <!-- <template slot-scope="scope">
              <div style="display: flex;justify-content: center">
                <el-link @click="AddPatientDialog(scope.row)" type="primary" :underline="false"><i class="el-icon-edit-outline"></i>修改</el-link>
                <el-link
                  :underline="false"
                  style="margin-left: 11px"
                  @click="DeleteProjectPatient(scope.row)"
                ><i class="el-icon-delete"></i>删除</el-link>

                <el-link :underline="false" style="margin-left: 11px" @click="FollowUpRecords(scope.row)"
                >随访记录</el-link>

                <img  @click="PatientPortrait(scope.row)" style="margin-left: 11px"
                    src="../../assets/image/icon/patient-touxiang.png" alt="">

                <el-link :underline="false" style="margin-left: 11px" @click="QuestionnaireFilling(scope.row)"
                >问卷填写</el-link>
              </div>
            </template> -->
            <template slot-scope="scope">
              <div style="display: flex;flex-wrap: wrap;justify-content: left;">
                <!-- v-has="'Patient/Edt'" -->
                <el-link :underline="false" @click="QuestionnaireFilling(scope.row)" style="">
                  <div style="display: flex;align-items: center;">
                    <img style="width: 16px;height: 16px;margin-top: 2px;" src="../../assets/image/icon/FillIn2.png"
                         alt="">
                    {{ textInfo[0] ? textInfo[0].LabelName : '新增问卷' }}
                  </div>
                </el-link>
                <el-link :underline="false" @click="FollowUpRecords(scope.row)" style="">
                  <div style="display: flex;align-items: center;">
                    <img style="width: 16px;height: 16px;margin-top: 3px;" src="../../assets/image/icon/takeNotes.png"
                         alt="">
                    {{ textInfo[1] ? textInfo[1].LabelName : '随访记录' }}
                  </div>
                </el-link>
                <el-link @click="PatientPortrait(scope.row)" :underline="false" style="">
                  <div style="display: flex;align-items: center;">
                    <img style="width: 16px;height: 16px;margin-top: 1px;"
                         src="../../assets/image/icon/PatientPortrait1.png" alt="">
                    患者画像
                  </div>
                </el-link>
                <el-link @click="AddPatientDialog(scope.row)" type="primary" :underline="false"><i
                  class="el-icon-edit-outline"></i>修改
                </el-link>
                <el-link :underline="false" style="" @click="DeleteProjectPatient(scope.row)"><i
                  class="el-icon-delete"></i>删除
                </el-link>


                <!-- <el-link  style="margin-left: 11px" @click="AddRobotIdDialog(scope.row)" type="primary" :underline="false"><i class="el-icon-phone-outline"></i>拨打</el-link> -->
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="人工外呼" width="205" align="center">
            <template slot-scope="scope">
              <div style="display: flex;justify-content: center">
                <button @click="OneTouchCall(scope.row)" class="yijian" style="margin-right: 10px;">
                  <img src="../../assets/image/icon/phone.png" style="vertical-align: middle;">
                  <span style="vertical-align: middle;">一键呼叫</span>
                </button>
                <el-link  :underline="false" @click="History(scope.row)">
                  <img src="../../assets/image/icon/search.png"  style="vertical-align: middle">历史
                </el-link>
              </div>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <div style="margin-left: 15px;margin-top: 10px">
        <Pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.rows"
                    @pagination="ProjectGetPageList"></Pagination>
      </div>
    </div>
    <add-patient :type="2" @EditSonToFather="Editfather" :item="PatientList" v-if="dialogFlag"
                 :dialog="dialogFlag"></add-patient>
    <div>
      <el-drawer :visible.sync="detailsDrawer" direction="rtl" :wrapperClosable="false" size="1920px">
        <div slot="title" class="header-title">
          <i class="el-icon-right" style="font-size: 20px;margin-left: 16px;margin-right: 21px"
             @click="detailsDrawer = false"></i>
          <span style="margin-right: 237px">随访记录</span>
          <span>随访详情</span>
        </div>
        <div class="detailsDrawer-components">
          <task-details :ProjectObj='ProjectObj' :PObj="PObj" v-if="detailsDrawer"></task-details>
        </div>
      </el-drawer>
    </div>
    <div class="QuestionnaireTable">
      <el-dialog :visible.sync="dialogVisible" width="30%">
        <div slot="title">
          <span style="color: #fff;">问卷填写</span>
        </div>
        <div>
          <p style="font-size: 16px;margin-bottom: 10px;">问卷列表</p>
          <el-select v-model="Qval" placeholder="请选择" @change="QuestionnaireTableChange"
                     style="margin-bottom: 10px;">
            <el-option v-for="(item, index) in QuestionnaireTable" :key="index" :label="item.Name"
                       :value="item.Id">
            </el-option>
          </el-select>
        </div>
        <div>
          <p style="font-size: 16px;margin-bottom: 10px;">访视次数</p>
          <el-input v-model="CObj" placeholder="请输入内容"></el-input>
          <!-- <el-select v-model="CObj" placeholder="请选择" value-key="Id" filterable allow-create>
              <el-option
                v-for="(item,index) in ItemList"
                :key="index"
                :label="item.Frq+'次,'+'时间:'+item.TriggerTime"
                :value="item">
              </el-option>
            </el-select> -->
        </div>
        <div slot="footer" class="btn">
          <button @click="dialogVisible = false">取 消</button>
          <button type="primary" @click="determine">确 定</button>
        </div>
      </el-dialog>
    </div>
    <div class="QuestionnaireFilling">
      <el-dialog :visible.sync="QuestionnaireFillingDialog" fullscreen destroy-on-close>
        <iframe :src="Url" frameborder="0" width="100%" height="1080px" ref="myFrame"></iframe>
      </el-dialog>
    </div>


    <!--    打电话-->
    <div class="call-phone">
      <el-dialog :visible.sync="dialogJSSip" width="500px" height="500px" top="20vh">
        <!--        :before-close="handleClose"-->
        <!--        center-->
        <JsSip :phone="phoneTel" :guid="guid" :callId="callId" v-on:unCall="unCall" ref="mychild"
               :key="callKey"></JsSip>
      </el-dialog>
    </div>
    <el-dialog :visible.sync="dialogSound" width="1060px">
      <!--      :before-close="soundClose"-->
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">一键呼叫录音记录</span>
      </div>
      <el-table :data="tabledataSound" :header-cell-style="{ 'text-align': 'center', background: 'rgb(240, 247, 253)' }"
                border height="300px" style="width: 100%">
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="CreateDate" label="拨打时间" width="200">
        </el-table-column>
        <el-table-column label="播放录音" min-width="150">
          <template slot-scope="scope">
            <div style="display: flex">
              <audio :src="api + scope.row.DataGuid + '.' + scope.row.Suffix" controls="controls"></audio>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="关联问卷" min-width="150" prop="Questionnaire">
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-link :underline="false" style="margin-right: 11px" @click="audioQuestionnaire(scope.row)">关联问卷
            </el-link>
            <el-link :underline="false"><i class="el-icon-delete"></i>删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination
        background
        layout="total, sizes,prev, pager, next, jumper"
        :total="callCount"
        :page-size="callSize"
        :current-page="callPage + 1"
        :page-sizes="[6, 12, 18]"
        @current-change="handleCall"
        @size-change="handleSizeCall"
      >
      </el-pagination> -->
      <div class="btn" style="margin-top: 20px">
        <button @click="dialogSound = false">取消</button>
        <button>导出</button>
      </div>
    </el-dialog>
    <div class="QuestionnaireTable">
      <el-dialog :visible.sync="robotVisible" width="30%">
        <div slot="title">
          <span style="color: #fff;">拨打</span>
        </div>
        <div>
          <p style="font-size: 16px;margin-bottom: 10px;">机器人列表</p>
          <el-select v-model="obj.RobotId" placeholder="请选择">
            <el-option v-for="(item, index) in ruleLIst" :key="index" :label="item.Name" :value="item.Id">
            </el-option>
          </el-select>
        </div>
        <div slot="footer" class="btn">
          <button @click="robotVisible = false">取 消</button>
          <button type="primary" @click="ExecuteOnce">确 定</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import TaskDetails from "@/components/task/TaskDetails";
import AddPatient from "@/components/PatientList/AddPatient";
import AddLabel from "@/components/PatientList/AddLabel";
import JsSip from "../../components/callPhone";
import ProjectManagement from "../../api/ProjectManagement"
import PatientCenter from "../../api/PatientCenter"
import questionnaire from "../../api/questionnaire"
import Pagination from "../Pagination/index.vue"
import {local} from "../../utils/storage"
import {mapState} from "vuex";
import derive from '../../api/derive';

export default {
  data() {
    return {
      robotVisible: false,
      admin: local.get("UserId"),
      PatientList: "",
      questionnaireVisible: false, // 录音关联问卷弹框
      detailsDrawer: false, // 随访记录抽屉
      callId: '', //更新删除电话记录Id
      api: 'https://wss.medicloud.org.cn:2443/rec/',
      tabledataSound: [
        {
          Id: 1,
          CreateDate: "20201-01-02 09:03:03",
          Questionnaire: "无"
        },
        {
          Id: 2,
          CreateDate: "20201-01-02 09:03:03",
          Questionnaire: "无"
        },
        {
          Id: 3,
          CreateDate: "20201-01-02 09:03:03",
          Questionnaire: "肌少症"
        }
      ],
      dialogVisible: false,
      dialogSound: false,
      QuestionnaireFillingDialog: false,
      callKey: '',
      dialogJSSip: false,
      guid: '',
      phoneTel: '',


      keyword: "",//关键字
      date1: "",
      date2: "",
      ProjectTable: [],
      QuestionnaireTable: [],
      ItemList: [],
      Qval: "",
      PObj: "",//储存患者对象
      CObj: "",//随访次数
      Url: "",
      ProjectObj: {},
      total: 0,
      listQuery: {
        Key: "",
        page: 1,
        rows: 10,
      },
      activityMultipleSelection: [],
      obj: {
        ProjectId: this.$route.query.Id,
        RobotId: '',
        ProjectPatientIds: [],
      },
      ruleLIst: [],
      devicePixelRatio: '',
      textInfo: []
    };
  },
  computed: mapState(["dialogFlag"]),
  created() {
    this.IsPC();
    this.ProjectGetPageList()
    this.GetOrder()
  },
  components: {
    JsSip, AddLabel, AddPatient, TaskDetails,
    Pagination

  },
  mounted() {
  },
  methods: {
    sortByName(obj1, obj2) {
      if ([obj1.Name, obj2.Name][0] == [obj1.Name, obj2.Name].sort((a, b) => a.localeCompare(b, "zh", {sensitivity: 'accent'}))[0]) {
        return -1
      } else {
        return 1
      }
    },
    sortByRisk(obj1, obj2) {
      if (obj2.RiskRating > obj1.RiskRating) {
        return 1
      } else {
        return -1
      }
      // console.log(obj1.RiskRating)
    },
    IsPC() {
      //   var userAgentInfo = navigator.userAgent; // 包含有关浏览器的信息// 非手机端
      //   var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];
      //   var flag = true;
      //   for (var v = 0; v < Agents.length; v++) {
      //     if (userAgentInfo.indexOf(Agents[v]) > 0) {
      //       flag = false;
      //       break;
      //     }
      //   }
      // return flag;
      this.devicePixelRatio = document.documentElement.clientWidth < 1100;
    },
    // 查询搜索
    search() {
      this.listQuery.page = 1;
      this.ProjectGetPageList();
    },
    //重置
    Reset() {
      // this.value.Id = 'all';
      // this.value.Name = '全部';
      this.listQuery.Key = '';
      this.keyword = '';
      this.date1 = '';
      this.date2 = '';
      this.ProjectGetPageList();
    },
    dialAll() {
      if (this.obj.ProjectPatientIds.length == 0) {
        return this.$message({
          message: '请先勾选一个患者',
          type: 'warning'
        });
      }
      this.GetRobotList(this.obj.ProjectId)
      this.robotVisible = true
    },
    //拨打
    ExecuteOnce() {
      if (this.obj.RobotId == '') {
        return this.$message({
          message: '请选择一个机器人',
          type: 'warning'
        });
      }
      ProjectManagement.ExecuteOnceData(this.obj).then(res => {
        console.log(res);
        if (res.data.Status == 1) {
          this.ProjectGetPageList()
          this.$message({
            type: 'success',
            message: '拨打成功!'
          });
          this.robotVisible = false
        } else {
          alert(res.data.Message)
        }
      })
    },
    //机器人列表
    GetRobotList(ProjectId) {
      ProjectManagement.GetRobotListData(ProjectId).then(res => {
        if (res.data.Status == 1) {
          this.ruleLIst = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, "机器人");
      })
    },
    AddPatientDialog(row) {
      console.log(row);
      this.$store.commit("dialogFlagFn", true);
      this.PatientList = row
    },
    DelFn(data) {
      console.log(data, "%%%");
      this.ProjectTable.forEach(item => {
        if (item.Id == data.ProjectPatientId) {
          item.LabelNameList = item.LabelNameList.filter(row => {
            return row != data.Name
          })
        }
      })
    },
    AddFn(data) {
      console.log(data, 'data管着ad')
      this.ProjectTable.forEach(row => {
        if (row.Id == data.ProjectPatientId) {
          row.LabelNameList.push(data.Name)
        }
      })
    },
    //项目患者列表
    ProjectGetPageList() {
      const parameter = {
        ProjectId: [this.$route.query.Id],
        Key: this.listQuery.Key,
        page: this.listQuery.page,
        rows: this.listQuery.rows,
        BeginTime: this.date1,
        EndTime: this.date2
      }
      ProjectManagement.ProjectGetPageListData(parameter).then(res => {
        if (res.data.Status == 1) {
          this.total = res.data.Data.total
          this.ProjectTable = res.data.Data.rows.map(item => {
            if (item.Birthday) {
              item.Birthday = item.Birthday.substring(0, 10)
            }
            if (item.CreateTime) {
              item.CreateTime = item.CreateTime.substring(0, 10)
            }
            return item
          })
        } else {
          alert(res.data.Message)
        }
        console.log(res, "项目患者列表");
      })
    },
    //选中方法
    handleActivitySelectionChange(selection) {
      //this.activityMultipleSelection自己定义的接受数组
      this.activityMultipleSelection = selection
      if (selection.length > 1) {
        let del_row = selection.shift()
        this.$refs.multipleTable.toggleRowSelection(del_row, false)
      }
    },
    //判断选中数量
    selectAll(selection) {
      this.obj.ProjectPatientIds = []
      selection.forEach(item => {
        this.obj.ProjectPatientIds.push(item.Id)
      })
      console.log(selection);
    },
    determine() {
      if (this.Qval != '') {
        this.Url = ""
        console.log(this.Qval, "%%%%%%%%%%%%%%%");
        // let Frq=null
        // if(this.CObj.Frq==null){
        //     Frq=0
        // }else{
        //     Frq=this.CObj.Frq
        // }
        const parameter = {
          ProjectId: this.PObj.ProjectId
        }
        this.QuestionnaireFillingDialog = true
        this.dialogVisible = false
        // this.Url=`${window.global_config.BASE_URL1}/#/P?sysData=${res.data.Data}&type=1&qid=${this.Qval}&userId=${this.PObj.Id}&userName=${this.PObj.Name}&fillinId=${local.get('UserId')}&fillinName=${local.get('UserName')}&phone=${this.PObj.Tel}&visitsNumber=${this.CObj}`
        // this.Url = `${window.global_config.BASE_URL1}/#/P?sysData=${this.PObj.CallBackInfo.CallBackParameterEncrypt}&type=4&qid=${this.Qval}&userId=${this.PObj.Id}&userName=${this.PObj.Name}&fillinId=${local.get('UserId')}&fillinName=${local.get('UserName')}&phone=${this.PObj.Tel}&visitsNumber=${this.CObj}`
        this.Url = `${window.global_config.BASE_URL1}/#/PcIframe?sysData=${this.PObj.CallBackInfo.CallBackParameterEncrypt}&type=4&qid=${this.Qval}&userId=${this.PObj.Id}&userName=${this.PObj.Name}&fillinId=${local.get('UserId')}&fillinName=${local.get('UserName')}&phone=${this.PObj.Tel}&visitsNumber=${this.CObj}`
      }
    },
    //问卷列表分页
    QuestionnaireGetPageList() {
      const parameter = {
        ProjectId: [this.$route.query.Id],
      }
      derive.CRFSurfaceData(parameter).then(res => {
        if (res.data.Status == 1) {
          this.QuestionnaireTable = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, "问卷分页列表");
      })
    },
    //选择问卷后调用随访接口
    QuestionnaireTableChange(e) {
      if (e.issueState == false) {
        this.Qval = ""
        alert("请先去发布问卷")
      }
    },
    // 方式次数
    // ProjectPatientId="患者id"
    // ProjectQuestionnaireId="问卷id"
    // ProjectId="项目id"
    // BlankQuestionnaire(ProjectPatientId,ProjectQuestionnaireId,ProjectId){
    //     const parameter={
    //        ProjectPatientId:ProjectPatientId,
    //        ProjectQuestionnaireId:ProjectQuestionnaireId,
    //        ProjectId:ProjectId,
    //     }
    //     PatientCenter.BlankQuestionnaireData(parameter).then(res=>{
    //        console.log(res,"次数");
    //       if(res.data.Status==1){
    //           this.ItemList= res.data.Data.ItemList
    //           this.Cid=res.data.Data.CurrentItem
    //       }else{
    //         alert(res.data.Message)
    //       }
    //     })
    // },
    //新增项目患者
    Addfather(data) {
      // this.ProjectTable.unshift(data)
      this.ProjectGetPageList()
      console.log(data);
    },
    //修改项目患者
    Editfather(data) {
      console.log(data);
      this.ProjectTable = this.ProjectTable.map(item => {
        if (item.Id == data.Id) {
          item = data
        }
        return item
      })
    },
    //删除项目患者
    DeleteProjectPatient(row) {
      const parameter = []
      parameter.push(row.Id)
      this.$confirm('此操作将永久删除该患者, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ProjectManagement.DeleteProjectPatientData(parameter).then(res => {
          if (res.data.Status == 1) {
            this.ProjectTable = this.ProjectTable.filter(item => {
              return item.Id != row.Id
            })
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          } else {
            alert(res.data.Message)
          }
          console.log(res, "删除项目患者");
        })
      })
    },
    refreashCall() {
      //刷新打电话组件
      this.callKey = Math.random()
    },
    unCall(val) {  //组件挂断电话的回调
      this.dialogJSSip = val
    },
    //一键呼叫
    OneTouchCall() {
    },
    //历史
    History() {
      this.dialogSound = true
    },
    noSound() {
      this.$message.warning('该患者还没有拨打电话')
    },
    audioQuestionnaire(row) { // 录音关联问卷
      this.questionnaireVisible = true
    },


    //患者画像
    PatientPortrait(row) {
      this.$router.push({path: "/PatientPortrait", query: {Id: this.$route.query.Id, qId: row.Id}});
    },
    QuestionnaireFilling(row) {//填写问卷
      this.Qval = ""
      this.CObj = ""
      console.log(row, 'row');
      this.PObj = row
      this.dialogVisible = true
      this.QuestionnaireGetPageList()
    },
    //打开随访记录弹框
    FollowUpRecords(row) {
      console.log(row, "$$$");
      this.PObj = row
      this.detailsDrawer = true
    },
    getFile(e) {
      console.log(e, "dwaaaaaaaaaaaa");
      let fd = new FormData();
      fd.append("file", e.target.files[0]);
      fd.append("ProjectId", this.$route.query.Id);
      ProjectManagement.PatientExportData(fd).then(res => {
        if (res.data.Status == 1) {
          this.$message({
            type: 'success',
            message: res.data.Message
          });
          this.ProjectGetPageList()
        } else {
          alert(res.data.Message)
        }
        console.log(res);
      })
    },
    TemplateDownload() {
      ProjectManagement.TemplateDownloadData().then(res => {
        if (res.data.Status == 1) {
          this.downLoad(res.data.Data)
        } else {
          alert(res.data.Message)
        }
        console.log(res);
      })
    },
    downLoad(record) {
      // console.log('record.httpAddress: ', record.httpAddress)
      const link = document.createElement('a')
      //_blank表示在新窗口打开链接
      link.target = '_blank'
      link.href = record
      document.body.appendChild(link)
      link.click()
      // document.body.removeChild(link) // 下载完成移除元素
    },
    async GetOrder() {
      let res = await PatientCenter.GetOrder({type: 'T'})
      console.log("res -->", res.data.Data)
      this.textInfo = res.data.Data
    }
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-link--inner {
  margin-right: 11px;
}

.tag_span {
  display: inline-block;
  min-width: 80px;
  height: 26px;
  background-color: #409eff;
  border: 1px solid #b3d8ff;
  border-radius: 4px;
  line-height: 24px;
  // color: #409eff;
  color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 8px;
  margin-left: 4px;
  font-size: 12px;
}

@import "../../styles/mixin.scss";

.QuestionnaireFilling {
  ::v-deep .el-dialog__headerbtn {
    top: 3px;
    font-size: 24px;
  }
}

.el-select {
  width: 100%;
}

.QuestionnaireTable {
  ::v-deep .el-input__inner {
    // width: 540px;
    width: 100%;
    height: 40px;
    line-height: 40px;
  }

  ::v-deep .el-input {
    height: 40px;
  }
}

.span-h {
  cursor: pointer;
}

::v-deep .el-input {
  height: 30px;
}

::v-deep .el-input__inner {
  height: 30px;
}

::v-deep .el-form-item__content {
  height: 34px;
}

::v-deep .el-button {
  height: 34px;
  font-weight: 400;
  @include add-size5($font_size_14);
}

::v-deep .el-button--primary {
  @include common_button_primary;
  margin-left: 20px;
}

::v-deep .el-input__icon {
  line-height: 20px;
  width: 20px;
}

::v-deep .el-col {
  height: 50px;
}

.tolead {
  width: 100%;
  height: 100%;
  min-width: 1440px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;

  .title {
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .left {
      margin-top: 0;
      margin-bottom: 1%;
      margin-left: 1%;
      width: 99%;

      .yuan {
        display: flex;
        align-items: center;

        ::v-deep .el-tag {
          border-radius: 50% !important;
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }

        float: right;
        margin-right: 20px;

        span {
          @include common_span
        }
      }

      span {
        display: inline-block;
        @include common_span;
        margin-left: 6px;
        margin-right: 6px;
      }

      ::v-deep .el-input__inner {
        height: 34px;
        line-height: 20px;
        border-radius: 4px;
        @include add-size($font_size_16);
      }

      ::v-deep .el-input {
        margin-right: 10px;
        width: 240px;
        height: 34px;
      }

      ::v-deep .el-input__inner {
        height: 34px;
      }

      ::v-deep .el-button.czhi {
        @include common_button_plain
      }
    }

    .right {
      width: 98%;
      margin: 0 auto 10px auto;
      display: flex;
      justify-content: space-between;

      //position: relative;
      ::v-deep .el-button.export-img-btn {
        @include common_button_plain
      }

      ::v-deep .el-button.dao-ru {
        @include common_button_primary;

        img {
          vertical-align: 0;
        }
      }

      .se2 {
        width: 130px;
        height: 34px;
        position: absolute;
        cursor: pointer;
        opacity: 0;
        padding-left: 10px;
      }
    }
  }
}

.con {
  ::v-deep .el-tag {
    border-radius: 50% !important;
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  .fen-rigth {
    width: 40%;

    input {
      width: 30px;
      height: 26px;
      margin-left: 1%;
      margin-right: 1%;
      border: 0;
      text-align: center;
      border-radius: 10%;
      font-size: 16px;
    }

    .span1-rigth {
      color: rgb(19, 104, 171);
    }
  }
}

.call-phone {
  ::v-deep .el-dialog {
    .el-dialog__body {
      padding: 0;
    }

    .el-dialog__header {
      width: 0;
      height: 0;
      padding: 0;
    }
  }
}

::v-deep .el-drawer {
  height: 100%;

  .el-drawer__header {
    padding: 0 20px 0 0;
    margin: 0;
  }

  .detailsDrawer-components {
    background: #F3F4F5;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
  }

  .header-title {
    width: 1201px;
    height: 60px;
    background: #FFFFFF;
    opacity: 1;
    line-height: 60px;
  }
}

.yijian {
  width: 100px;
  height: 34px;
  background: rgba(57, 120, 231, 0.15);
  border: 1px solid rgba(51, 136, 255, 0.5686274509803921);
  border-radius: 4px;
  color: #3388FF;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {

  // ::v-deep .el-table{
  //   overflow-y: scroll;
  // }
  ::v-deep .el-table__fixed-right {
    right: 0px !important;
  }

  .tolead {
    min-width: 0 !important;
    padding: 0 !important;

    ::v-deep .el-table__fixed-right-patch {
      width: 10px !important;
      background-color: rgb(240, 247, 253) !important;
    }

    .title {

      .left {
        div {
          margin-bottom: 5px;
        }
      }

      ::v-deep .el-input__inner {
        width: 130px !important;
      }

      ::v-deep .el-input {
        width: 130px !important;
      }

      .el-button {
        margin-left: 0 !important;
        margin-right: 5px;
      }

      .yuan {
        align-items: center;
        margin-right: 10px !important;
        margin-top: 10px;
      }

      .right {

        ::v-deep .el-button {
          margin-left: 0 !important;
          margin-right: 5px;
        }
      }
    }

    .QuestionnaireTable {
      ::v-deep .el-dialog {
        width: 60% !important;
      }
    }
  }

  ::v-deep .el-drawer {
    width: 100% !important;

    .detailsDrawer-components {
      height: 100%;

      .task-details-right {
        flex: 1;
      }
    }
  }
}
</style>

