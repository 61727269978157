import Vue from "vue";
import Vuex from "vuex";
import {local, session} from "@/utils/storage";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		menuindex: 1,
		ElTabs: "first",
		Submenu: false,
		dialogFlag: false,
		dialogVisible: true,
		LOADING: false,
		SysData: '',
		menuFlag: local.get('layout') === 'LTOR',
		navList:[]
	},
	mutations: {
		dialogFlagFn(state, flag) {
			state.dialogFlag = flag
		},
		//项目管理里面的菜单
		ElTabsFn(state, name) {
			state.ElTabs = name
		},
		SubmenuFn(state, flag) {
			state.Submenu = flag
		},
		menustate(state, val) {//控制菜单高亮
			session.set("menuindex", val)
			state.menuindex = val
		},
		showLoading(state) {
			state.LOADING = true;
		},
		hideLoading(state) {
			state.LOADING = false;
		},
		setNav(state,val){
			state.navList = val
		}
	},
	actions: {},
	modules: {},
});
