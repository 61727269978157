// 登录页接口

import axios from "./Interceptor.js"
import qs from "qs"

let address = window.global_config.BASE_URL2

let apiLogin = {
    login: (info) => { // 登录接口
        return axios({
            method: "post",
            url: `${address}entry/Login`,
            data: qs.stringify(info),
        })
    },
    useTokengetInfo: () => { // 登录接口
        return axios({
            method: "get",
            url: `${address}Entry/GetCurrentUser`,
        })
    },
    RefreshToken: (data) => { // 登录接口
        return axios({
            method: "post",
            url: `${address}Entry/RefreshToken`,
            data
        })
    },
    getImg: () => { // pin
        return axios({
            method: "get",
            url: `${address}entry/GetVcImg`,
        })
    },
    MyMissionData: () => { // 我的任务
        return axios({
            method: "post",
            url: `${address}FollowUp/MyTaskMaster`,
        })
    },
    MyMissionDetailedData: (info) => { // 我的任务明细
        return axios({
            method: "post",
            url: `${address}FollowUp/MyTaskDetail`,
            data: info
        })
    },
    // 个性化登陆
    diyLogin: (id) => axios({url: `${address}Organization/GetConfig?id=${id}`, method: "get"}),
    GetExtends : () => { // 获取组织配置地址
        return axios({
            method: "post",
            url: `${address}Organization/GetExtends`,
        })
    },
}

export default apiLogin
