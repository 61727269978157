<template>
  <div class="main" :style="{ height: $store.state.menuFlag ? '100%' : 'calc(100% - 70px)' }">
    <div class="box">
      <div class="content">
        <div class="content-header" v-if="centerText1 || centerText2">
          <div style="display: flex;height: 90px">
            <!--            <div-->
            <!--                style="width: 90px;height: 90px;display: flex;justify-content: center;align-items: center;background-color: white;border-radius: 50%;">-->
            <!--              <img :src=" Sex == '1' ? require('../../assets/image/80nan.png') : require('../../assets/image/80nv.png')"-->
            <!--                   alt="" style="width: 90%;height: 90%;">-->
            <!--            </div>-->
            <div class="header-left">
              <p class="p-header-title">{{ centerText1 }}</p>
              <span style="display:inline-block;">{{ centerText2 }}</span>
            </div>
          </div>
          <!--          <div style="display: flex;display: none;">-->
          <!--            <div style="display: flex">-->
          <!--              <div style="width: 46px;height: 46px"></div>-->
          <!--              <div class="header-right">-->
          <!--                <p>项目数</p>-->
          <!--                <span>{{ Summary.ProjectTotal }}</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div style="display: flex">-->
          <!--              <div style="width: 46px;height: 46px"></div>-->
          <!--              <div class="header-right2">-->
          <!--                <p>问卷数</p>-->
          <!--                <span>{{ Summary.QuestionnaireTotal }}</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="project-query-add">
          <div class="query">
            <span>关键字:</span>
            <el-input class="query-input" v-model="listQuery.Key" placeholder="请输入关键字搜索"></el-input>
            <el-button icon="el-icon-search" @click="search">查询</el-button>
          </div>
          <div v-if="admin != '00000000-0000-0000-0000-000000000000'">
            <!-- v-has="'Project/Add'" -->
            <add-project :formType="1" @refresh="refresh" @EditSonToFather="Editfather"></add-project>
          </div>
        </div>
        <!-- <div style="margin-top: 20px;margin-bottom: 10px;flex: 1;" class="tableaa">
          <el-table style="width: 98%;margin:0 auto;" height="96%"
                    :header-cell-style="{ 'text-align': 'center', background: 'rgb(240, 247, 253)' }" :data="tableData"
                    border>
            <el-table-column type="index" width="60" align="center" label="序号">
            </el-table-column>
            <el-table-column v-if="admin == '00000000-0000-0000-0000-000000000000'" prop="OrganizationName"
                             label="组织名称"
                             min-width="80" align="center">
            </el-table-column>
            <el-table-column label="项目名称" min-width="168" align="center" :sortable="true"
                             :sort-method="sortByVesselName">
              <template slot-scope="scope">
                <span @click="Projectdetails(scope.row)">{{ scope.row.Name }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="No" sortable label="项目编号" width="120">
            </el-table-column>
            <el-table-column align="center" prop="Leader" label="课题管理员" width="120">
            </el-table-column>
            <el-table-column
                align="center"
                prop="Admin"
                label="项目负责人"
                width="120"
            >
            </el-table-column>
            <el-table-column align="center" label="项目单位" prop="LDeptName" min-width="120">
            </el-table-column>
            <el-table-column align="center" label="参研单位" min-width="60">
              <template #default="{ row }">
                <el-link :underline="false" style="margin-right: 11px" @click="handleClick(row)"><i
                    class="el-icon-search"></i>查看
                </el-link>
              </template>
            </el-table-column>
            <el-table-column label="起止时间" width="160" align="center">
              <template slot-scope="scope">
                <p>{{ scope.row.StartDate.substr(0, 10) }}</p>
                <p>~</p>
                <p>{{ scope.row.EndDate.substr(0, 10) }}</p>
              </template>
            </el-table-column>
            <el-table-column label="项目周期" width="100" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.Cycle }}</span>
              </template>
            </el-table-column>
            <el-table-column label="项目状态" width="100" align="center">
              <template slot-scope="scope">
                <p>{{ scope.row.State }}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="194">
              <template slot-scope="scope">
                <div style="display: flex;flex-wrap:wrap">
                  <el-link :underline="false" style="margin-right: 11px" @click="Projectdetails(scope.row)"><i
                      class="el-icon-search"></i>查看
                  </el-link>
                  <add-project :formType="2" :editForm="scope.row"
                               v-if="admin !== '00000000-0000-0000-0000-000000000000' && RoleType === 'D'"></add-project>
                  <el-link v-if="admin !== '00000000-0000-0000-0000-000000000000' && RoleType === 'D'" :underline="false"
                           @click="DeleteProject(scope.row)"><i class="el-icon-delete"></i>删除
                  </el-link>
                  <el-link
                      v-if="admin !== '00000000-0000-0000-0000-000000000000' && RoleType === 'D' && scope.row.IsRtc"
                      :underline="false" @click="setRandom(scope.row)"><i
                      class="el-icon-edit-outline"></i><span>RCT研究设置</span></el-link>
                  <el-link :underline="false" style="margin-right: 11px"
                           @click="projectDesc(scope.row)"><i
                      class="el-icon-search"></i>项目简介
                  </el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="width: 98%;margin:0 auto;">
          <Pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.rows" @pagination="GetList">
          </Pagination>
        </div> -->

        <div style="margin-top: 20px;margin-bottom: 10px;flex: 1;" class="tableaa">
          <el-table :data="tableData" style="width: 98%;margin:0 auto;" height="96%"
                    :header-cell-style="{ 'text-align': 'center', background: 'rgb(240, 247, 253)' }"
                    row-key="Id" border default-expand-all :tree-props="{ children: 'children' }">

            <el-table-column width="100" align="center" label="序号">
              <template #default="scope">
                <span v-if="scope.row.level === 0">{{ scope.row.index }}</span>
                <span v-else-if="scope.row.level === 1">({{ scope.row.index }})</span>
                <span v-else class="level3">{{ scope.row.index }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="admin == '00000000-0000-0000-0000-000000000000'" prop="OrganizationName"
                             label="组织名称"
                             min-width="80" align="center">
            </el-table-column>
            <el-table-column label="项目名称" min-width="168" align="center" :sortable="true"
                             :sort-method="sortByVesselName">
              <template slot-scope="scope">
                <span @click="Projectdetails(scope.row)">{{ scope.row.Name }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="No" sortable label="项目编号" width="120">
            </el-table-column>
            <el-table-column align="center" prop="Leader" label="课题管理员" width="120">
            </el-table-column>
            <el-table-column align="center" prop="Admin" label="项目负责人" width="120">
            </el-table-column>
            <el-table-column align="center" label="项目单位" prop="LDeptName" min-width="120">
            </el-table-column>
            <el-table-column align="center" label="参研单位" min-width="80">
              <template #default="{ row }">
                <el-link :underline="false" style="margin-right: 11px" @click="handleClick(row)"><i
                    class="el-icon-search"></i>查看
                </el-link>
              </template>
            </el-table-column>
            <el-table-column label="起止时间" width="160" align="center">
              <template slot-scope="scope">
                <p>{{ scope.row.StartDate.substr(0, 10) }}</p>
                <p>~</p>
                <p>{{ scope.row.EndDate.substr(0, 10) }}</p>
              </template>
            </el-table-column>
            <el-table-column label="项目周期" width="100" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.Cycle }}</span>
              </template>
            </el-table-column>
            <el-table-column label="项目状态" width="100" align="center">
              <template slot-scope="scope">
                <p>{{ scope.row.State }}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="210">
              <template slot-scope="scope">
                <div style="display: flex;flex-wrap:wrap">
                  <template v-if="scope.row.Enable">
                    <el-link :underline="false" style="margin-right: 11px" @click="Projectdetails(scope.row)"><i
                        class="el-icon-search"></i>查看
                    </el-link>

                    <add-project :formType="2" :editForm="scope.row"
                                 v-if="admin !== '00000000-0000-0000-0000-000000000000' && RoleType === 'D'"></add-project>

                    <el-link v-if="admin !== '00000000-0000-0000-0000-000000000000' && RoleType === 'D'"
                             :underline="false"
                             @click="DeleteProject(scope.row)"><i class="el-icon-delete"></i>删除
                    </el-link>

                    <el-link
                        v-if="admin !== '00000000-0000-0000-0000-000000000000' && RoleType === 'D' && scope.row.IsRtc"
                        :underline="false" @click="setRandom(scope.row)"><i
                        class="el-icon-edit-outline"></i><span>RCT研究设置</span></el-link>

                    <el-link :underline="false" style="margin-right: 11px" @click="projectDesc(scope.row)"><i
                        class="el-icon-search"></i>项目简介
                    </el-link>

                    <add-project :formType="3" @refresh="refresh" @EditSonToFather="Editfather"
                                 :ParentId="scope.row.Id"></add-project>

                  </template>
                  <span v-else>无权限</span>
                </div>
              </template>
            </el-table-column>

          </el-table>
        </div>

      </div>
    </div>
    <div>
      <!-- <el-dialog
        :visible.sync="guanliandialog"
        v-dialogDrag
        width="800"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">关联机器人</span>
        </div>
        <el-table
          ref="multipleTable"
          :data="rootData"
          tooltip-effect="dark"
          border
          height="330"
          style="width: 100%"
        >
          <el-table-column
            label="序号"
            type="index"
            align="center"
            width="80">
          </el-table-column>
          <el-table-column label="问卷名称" min-width="150" align="center" prop="questionnaireName">
          </el-table-column>
          <el-table-column label="机器人ID" min-width="100" align="center" prop="rootId">
          </el-table-column>
          <el-table-column label="关联机器人" min-width="100" align="center" prop="Y">
          </el-table-column>
        </el-table>
        <div class="btn" style="margin-top: 32px">
          <el-button @click="guanliandialog=false">关闭</el-button>
        </div>
      </el-dialog> -->
    </div>
    <div>
      <el-dialog :visible.sync="HospitalDialog" width="700">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">参与单位</span>
        </div>
        <el-table ref="multipleTable" :data="HospitalData" tooltip-effect="dark" border height="330" style="width: 100%"
                  :header-cell-style="{ background: 'rgb(240, 247, 253)' }">
          <el-table-column label="序号" type="index" align="center" width="80">
          </el-table-column>
          <el-table-column label="参与单位" min-width="150" align="center" prop="HospitalName">
          </el-table-column>
        </el-table>
        <!-- <pagination
          :total="totalHospitalList"
          :page.sync="HospitalList.PageIndex"
          :limit.sync="HospitalList.PageSize"
          @pagination="getList"
        >
        </pagination> -->
        <div class="btn" style="margin-top: 32px">
          <el-button @click="HospitalDialog = false">关闭</el-button>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-dialog :visible.sync="RandomDialog" width="900" v-if="RandomDialog">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">设置RCT研究</span>
        </div>
        <random-ization :projectId="projectId" @random="ization"></random-ization>
      </el-dialog>
    </div>
    <el-dialog :visible.sync="visible" width="700" v-if="visible">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">参研单位</span>
      </div>
      <UnitMaintenance :flag="false"/>
      <div class="btn" style="margin-top: 32px">
        <el-button @click="visible = false">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="projectVisible" top="5vh" width="70vw" v-if="projectVisible">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">项目简介</span>
      </div>
      <project-info></project-info>
      <div class="btn" style="margin-top: 32px">
        <el-button @click="projectVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ProjectManagement from "@/api/ProjectManagement.js"
import {local} from "@/utils/storage";
import AddProject from "@/components/projectManagement/AddProject";
import RandomIzation from "@/components/projectManagement/RandomIzation";
import Pagination from "@/components/Pagination/index";
import UnitMaintenance from "@/components/projectManagement/UnitMaintenance"
import ProjectInfo from "@/components/projectManagement/ProjectInfo";

export default {
  name: "HomePage",
  data() {
    return {
      projectVisible: false,
      RandomDialog: false, // 随机化弹框
      HospitalDialog: false,
      totalHospitalList: 20,
      HospitalList: {
        Key: "",
        PageIndex: 1,
        PageSize: 5,
      },
      HospitalData: [
        {
          id: 1,
          HospitalName: "老年女性盆底功能障碍早期识别问卷",
        }
      ],
      totalRoot: 0,
      listRoot: {
        Key: "",
        PageIndex: 1,
        PageSize: 5,
      },
      rootData: [
        {
          id: 1,
          questionnaireName: "老年女性盆底功能障碍早期识别问卷",
          rootId: 111,
          Y: "是"
        },
      ],
      guanliandialog: false,
      timer: "",//定义一个定时器的变量
      currentTime: new Date(), // 获取当前时间
      UserName: local.get("UserName"),
      TitleName: local.get('TitleName') !== 'undefined' ? local.get('TitleName') : '',
      admin: local.get("UserId"),
      RoleType: local.get('RoleType'),
      Sex: local.get('Sex'),
      tableData: [], // 表格数据
      total: 0,
      listQuery: {
        Key: "",
        page: 1,
        rows: 10,
      },
      Summary: {},
      projectId: '',
      visible: false,
      centerText1: local.get('centerText1'),
      centerText2: local.get('centerText2'),
    }
  },
  computed: {
    date(start, end) {
      let day = Date.parse(end) - Date.parse(start)
      let days = parseInt(day / (1000 * 60 * 60 * 24));
      return days
    }
  },
  components: {
    AddProject,
    RandomIzation,
    Pagination,
    UnitMaintenance,
    ProjectInfo
  },
  created() {
    this.GetList()
    this.QueryProjectSummary()
    this.getTimer()
  },
  methods: {
    //通过文字进行排序
    sortByVesselName(obj1, obj2) {
      if ([obj1.Name, obj2.Name][0] == [obj1.Name, obj2.Name].sort((a, b) => a.localeCompare(b, "zh", {sensitivity: 'accent'}))[0]) {
        return -1
      } else {
        return 1
      }
    },
    ization(data) {
      this.RandomDialog = data
    },
    // 新增项目后刷新列表
    refresh() {
      this.GetList()
    },
    //编辑项目
    Editfather(data) {
      this.tableData = this.tableData.map(item => {
        if (item.Id == data.Id) {
          item = data
        }
        return item
      })
    },
    //查看
    Projectdetails(row) { // 打开查看项目弹框
      if (!row.Enable) return
      console.log(row)
      local.set("ProjectName", row.Name)
      local.set('IsRtc', row.IsRtc)
      this.$store.commit("ElTabsFn", "first");
      this.$router.push({
        path: "/seeProject",
        query: {
          Id: row.Id
        }
      })
    },
    DeleteProject(row) {//打开删除项目弹框
      this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const parameter = []
        parameter.push(row.Id)
        ProjectManagement.DeleteProjectData(parameter).then(res => {
          if (res.data.Status == 1) {
            this.tableData = this.tableData.filter(item => {
              return item.Id != row.Id
            })
            this.$message({
              type: 'success',
              message: res.data.Message
            });
            this.GetList()
          } else {
            alert(res.data.Message)
          }
        })
      })
    },
    setRandom(row) { // 打开随机化弹框
      console.log(row);
      this.projectId = row.Id
      this.RandomDialog = true
    },
    //项目列表
    async GetList() {
      ProjectManagement.GetListData(this.listQuery).then(res => {
        console.log(res.data.Data, '项目列表数据')
        if (res.data.Status == 1) {
          let arr = this.convertToTree(res.data.Data)
          this.tableData = arr
          
          console.log("=>(HomePage.vue:465) this.tableData", this.tableData);

          // this.total = res.data.Data.total
        }
      })
    },
    convertToTree(flatArray) {
      const map = {};
      const tree = [];

      // 构建映射
      flatArray.forEach((item) => {
        map[item.Id] = {...item, children: [], level: 0, index: ''};
      });

      // 构建树形结构
      flatArray.forEach(item => {
        if (item.ParentId !== null && map[item.ParentId]) {
          const parent = map[item.ParentId];
          map[item.Id].level = parent.level + 1;
          parent.children.push(map[item.Id]);
        } else if (item.ParentId === null) {
          tree.push(map[item.Id]);
        }
      });

      // 给每个节点分配序号
      function assignIndex(nodes) {
        nodes.forEach((node, i) => {
          node.index = `${i + 1}`;
          if (node.children.length > 0) {
            assignIndex(node.children);
          }
        });
      }

      assignIndex(tree);

      return tree;
    },

//项目信息总汇
    async QueryProjectSummary() {
      ProjectManagement.QueryProjectSummaryData().then(res => {
        if (res.data.Status == 1) {
          this.Summary = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, "项目信息综合");
      })
    },
    guanlian(row) {
      this.guanliandialog = true
    },
    HospitalListDialog(row) {
      this.HospitalDialog = true
    },
    // 搜索
    search() {
      console.log('search', this.listQuery.Key);
      this.GetList();
    },

    // 防抖函数
    debounce(fn) {
      let t = null
      //往这里传e
      return function (e) {
        if (t) {
          clearTimeout(t)
        }

        t = setTimeout(function () {
          //再带给fn
          fn(e)
        }, 1000)
      }
    },

    async getTimer() { // 获取当前时间
      this.timer = setInterval(() => {
        this.currentTime = new Date();
      }, 1000);
    },
    async handleClick(row) {
      this.$route.query.Id = row.Id
      this.visible = true
      console.log(this.$route.query);
      // console.log("row -->", row)
      // let param = {
      //   "ProjectId": row.Id,
      //   "Key": "",
      //   "page": 1,
      //   "rows": 10
      // }
      // let res = await ProjectManagement.CompanyListData(param)
      // console.log("res -->", res.data.Data.rows)
    },
    projectDesc(row) {
      console.log(this.projectVisible)
      this.projectVisible = true;
      this.$route.query.Id = row.Id
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/mixin.scss";

.iscolor {
  color: #666 !important;
}

.main {
  width: 100%;
  min-width: 1440px;
  overflow: hidden;

  .box {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    margin: 0 auto;
    border-radius: 10px;

    .content {
      width: 100%;
      height: 100%;
      margin-top: 20px;
      background: #fff;
      padding: 20px 0px 10px 0px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .content-header {
        width: 98%;
        height: 120px;
        display: flex;
        align-items: center;
        background: #FBFBFD;
        border: 1px solid #E9E9E9;
        margin: 0 auto 20px auto;
        padding-left: 40px;
        box-sizing: border-box;
        background-image: url('../../assets/image/headerBg.png');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 20px;

        .header-left {
          height: 90px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 5px;

          .p-header-title {
            font-size: 30px;
            font-weight: 400;
            line-height: 40px;
            color: #333333;
          }

          span {
            @include common_span;
            font-size: 20px;
          }
        }

        .header-right {
          width: 120px;
          text-align: center;

          p {
            width: 120px;
            @include common_span;
            color: #3388FF;
          }

          span {
            font-size: 34px;
            color: #3388FF;
          }
        }

        .header-right2 {
          width: 120px;
          text-align: center;

          p {
            width: 120px;
            @include common_span;
            color: #FF9900;;
          }

          span {
            font-size: 34px;
            color: #FF9900;
          }
        }
      }

      .project-query-add {
        width: 98%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;

        span {
          @include common_span;
        }

        .query-input {
          width: 240px;
          height: 34px;
          margin-right: 20px;

          .el-input__inner {
            width: 240px;
            height: 34px;
            @include add-size($font_size_16)
          }
        }

        .query {
          ::v-deep .el-button {
            @include common_button_primary
          }
        }
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .main {
    width: 100%;
    min-width: 0 !important;

    .content {
      display: flex;
      flex-direction: column;

      .table {
        flex: 1;
      }

      .tableaa {
        margin-bottom: 0 !important;
      }
    }

    .header-left {
      width: 250px;
    }

    .content-header {
      img {
        height: 85% !important;
      }

      .p-header-title {
        font-size: 18px !important;
        line-height: 24px !important;
      }
    }
  }
}

.level3 {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  display: inline-block;
  border: 1px solid #585858;
  border-radius: 50%;
}
</style>
